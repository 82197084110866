import { uploadsService } from '../_services';
import { uploadsConstants, userConstants } from '../_constants';
import { alertActions } from './';
import { history } from '../_helpers';

export const uploadsActions = {
    getAllUploads,
    getFile,
    deleteStory,
    approveStory,
    closeDetails,
    storyItemChanged,
    editStory,
    uploadApproval
};

function getAllUploads() {
    return dispatch => {
        dispatch(request());
        uploadsService.getAllUploads()
            .then(
                (uploads) => {
                    dispatch(success(uploads));

                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            )
    };
    function request() { return { type: uploadsConstants.GETALL_UPLOADS_REQUEST } }
    function success(uploads) { return { type: uploadsConstants.GETALL_UPLOADS_SUCCESS, uploads } }
    function failure(error) { return { type: uploadsConstants.GETALL_UPLOADS_FAILURE, error } }
}

function getFile(filename,uploadId, type) {
    
    return dispatch => {
        uploadsService.getFile(uploadId,type)
            .then(
                (file) => {
                    file.filename = filename;
                    file.filetype = type;
                    dispatch(success(file));
                    dispatch(alertActions.clear());



                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );

        function success(file) { return { type: userConstants.DOWNLOAD_SUCCESS, file } }
        function failure(error) { return { type: userConstants.DOWNLOAD_FAILURE, error } }

    }
}

    function deleteStory(id) {
     
        return dispatch => {
            uploadsService.deleteStory(id)
                .then(
                    () => {

                        dispatch(success(id));
                        dispatch(alertActions.success('The story with id '+id+' has been deleted.'));
                      
                    }, 
                    error => {
                        console.log(error);
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));
                    }
                );

            function success(id) { return { type: uploadsConstants.DELETE_UPLOAD_SUCCESS, id } }
            function failure(error) { return { type: uploadsConstants.DELETE_UPLOAD_FAILURE, error } }

        }
    }

    function approveStory(id) {
      
        return dispatch => {
            uploadsService.approveStory(id)
                .then(
                    (status) => {
                       
                        dispatch(success(id));
                        var state = status?'approved':'not approved';
                        dispatch(alertActions.success('The story with id '+id+' is '+state));
                       

                    },
                    error => {
                        console.log(error);
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));
                    }
                );

            function success(id) { return { type: uploadsConstants.APPROVE_UPLOAD_SUCCESS,id }}
            function failure(error) { return { type: uploadsConstants.APPROVE_UPLOAD_FAILURE, error } }

        }
    }
    function closeDetails(){
        return dispatch => {
            dispatch(close());
          
            function close() { return { type: uploadsConstants.CLOSE_UPLOAD } }
        }

       
    }
    function storyItemChanged(uploadModel){
        return dispatch => {
            dispatch(changeModel(uploadModel));
          
            function changeModel(uploadModel) { return { type: uploadsConstants.CHANGE_UPLOAD_MODEL,uploadModel } }
        }
    }
    function editStory(uploadModel,story) {
      
        return dispatch => {
            uploadsService.editStory(story)
                .then(
                    (status) => {
                       
                        dispatch(success(uploadModel));

                        dispatch(alertActions.success('The story with id '+uploadModel.id+' is updated'));
                       

                    },
                    error => {
                        console.log(error);
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));
                    }
                );

            function success(uploadModel) { return { type: uploadsConstants.EDIT_UPLOAD_SUCCESS,uploadModel } }
            function failure(error) { return { type: uploadsConstants.EDIT_UPLOAD_FAILURE, error } }

        }
    }

    function uploadApproval(video) {
   
        return dispatch => {
           
            uploadsService.uploadApproval(video)
    
            .then(
                () => { 
                    dispatch(success(video));
                    history.push('/');
                    dispatch(alertActions.success('Your approval date has been submitted for review.'));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
         
        };
        function success(video) { return { type: userConstants.VIDEO_SUCCESS, video } }
        function failure(error) { return { type: userConstants.VIDEO_FAILURE, error } }
    }


