import React from 'react';
import { Route } from 'react-router-dom';
import { history } from '../../_helpers';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class CookieMessage extends React.Component {

    constructor(props) {
        super(props);
       

        this.handleCookieAllowed = this.handleCookieAllowed.bind(this);
    }

    
    handleCookieAllowed() {
 

        this.props.dispatch(userActions.acceptCookiMessage());

    }
  

    render() {
        const{pathname} = history.location;
     
     
        return (

            <div>
                {this.props.showCookieMessage != 'no' && ( pathname == "/" || pathname.toLowerCase() == "/login" || pathname.toLowerCase() == "/adminlogin")  &&

                    <div className="cookies">
                        <div className="container">

                            <div className="row cookie-content">
                                <div className="col-sm-6 right">
                                    <div className="bayerLogo right"></div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p> <strong>Usage of cookies</strong></p>
                                            <p>
                                                We would like to use cookies to improve<br /> your future experience on our website.<br /> You can manage or withdraw your <br />consent at any time. Detailed<br /> information about the use of cookies on<br /> this website can be found in our <a href="/privacystatement"> Privacy<br /> Statement.</a><br />
                                                <a href="/privacystatement#performanceCookies">Cookie Settings</a>

                                            </p>
                                            <br />
                                            <a className=" btn btn-primary btn-md" onClick={this.handleCookieAllowed}> Ok</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                }
                {this.props.showCookieMessage != 'no' && ( pathname !== "/" || pathname.toLowerCase() !== "/login" || pathname.toLowerCase() !== "/adminlogin") &&
                    <div className="top-bar-cookies">
                        <div className="container">

                            <div className="row">
                                <div className="col-sm-10">
                                    <p>
                                        <strong>Usage of cookies</strong>
                                    </p>
                                    <p>
                                        We would like to use cookies to improve your future experience on our website. You can manage or withdraw your consent at any time. Detailed information about the use of cookies on this website can be found in our <a href="/privacystatement"> Privacy Statement.</a><br />
                                        <a href="/privacystatement#performanceCookies">Cookie Settings</a>
                                    </p>

                                </div>
                                <div className="col-sm-2">
                                    <button className=" btn btn-primary btn-md" onClick={this.handleCookieAllowed}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }




            </div>

        )

    };
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user, showCookieMessage } = authentication;
    return {
        user,
        showCookieMessage
    };
}

const connectedCookieMessage = connect(mapStateToProps)(CookieMessage);
export { connectedCookieMessage as CookieMessage };
