import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QuickCms } from '../../_components/QuickCms';
import { VideoUpload } from '../../_components/VideoUpload/Videoupload';
import { UploadApproval } from '../../_components/UploadApproval/UploadApproval';
import { UploadHb } from '../../_components/UploadHb/UploadHb';
import { UploadChooser } from '../../_components/UploadChooser/UploadChooser';

class UploadPage extends React.Component {


    constructor(props) {
        super(props);

    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextprop) {

    }
    render() {
        const { user, formType } = this.props;

        return (
            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <QuickCms name="StoryPageTitle">
                            <h2 className="uploadChooserTitle">What would you like to add?</h2>
                        </QuickCms>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col-md-12">
                        <UploadChooser />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        {formType == "story" && <VideoUpload />}
                        {formType == "approval" && <UploadApproval />}
                        {formType == "hb" && <UploadHb />}
                    </div>
                </div>

            </div>

        )
    }
}

function mapStateToProps(state) {
    const { authentication, video } = state;
    const { user } = authentication;
    const { formType } = video;
    return {
        user,
        formType
    };
}

const connectedUploadPage = connect(mapStateToProps)(UploadPage);
export { connectedUploadPage as UploadPage };