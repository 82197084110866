import { authHeaderjSON, config } from '../_helpers';
export const timelineService = {
    getTimeline,
   
};

function getTimeline() {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON() 
    };
  
    return fetch(config.apiUrl + '/upload/getTimeline', requestOptions)
        .then(handleResponse, handleError) .then(timeline => {

            return timeline;
            });;
       
}


function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
           
            if (contentType && contentType.includes("application/json")) {
             
                response.text().then(text => {
                   
                    var json = JSON.parse( text.replace(new RegExp("#HOST_NAME_HERE#", 'g'), config.apiUrl));
                  

                   return resolve(json)
                });
                //response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}
