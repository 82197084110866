import { userConstants } from '../_constants';

export function video(state = {formType:"story"}, action) {
  switch (action.type) {
    case userConstants.VIDEO_REQUEST:
      return { ...state,sending: true };
    case userConstants.VIDEO_SUCCESS:
      return {...state};
    case userConstants.VIDEO_FAILURE:
      return {...state};
      case userConstants.VIDEO_CHOICE_CHANGE:
    
      return {...state,formType:action.formType};
    default:
      return state
  }
}