import { uploadsConstants } from '../_constants';

export function uploads(state = {}, action) {
  switch (action.type) {
    case uploadsConstants.GETALL_UPLOADS_REQUEST:
      return {
        loading: true
      };
    case uploadsConstants.GETALL_UPLOADS_SUCCESS:
      return {
        items: action.uploads
      };
    case uploadsConstants.GETALL_UPLOADS_FAILURE:
      return {
        error: action.error
      };
    case uploadsConstants.DELETE_UPLOAD_REQUEST:

      return {};
    case uploadsConstants.DELETE_UPLOAD_SUCCESS:
      // remove deleted upload from state
      return {
        ...state,
        uploadModel: null,
        items: state.items.filter(upload => upload.id != action.id),

      };
      return { ...state, uploadModel: null }
    case uploadsConstants.DELETE_UPLOAD_FAILURE:

      return {};

    case uploadsConstants.APPROVE_UPLOAD_SUCCESS:

      return {
        ...state,
        uploadModel: null,
        items: state.items.map(upload => {

          if (upload.id == action.id) {
            var status = upload.isApproved;
            return { ...upload, isApproved: !status, isEdited: true };
          }
          return upload;
        })

      }
    case uploadsConstants.EDIT_UPLOAD_SUCCESS:

      return {
        ...state,
        uploadModel: null,
        items: state.items.map(upload => {
       

          if (upload.id == action.uploadModel.id) {

            return {...action.uploadModel, isEdited: true,isDirty: false };
          }

          return upload;
        })

      }
    case uploadsConstants.EDIT_UPLOAD_FAILURE:
      return {
        error: action.error
      };
    case uploadsConstants.CHANGE_UPLOAD_MODEL:

      return { ...state, uploadModel: action.uploadModel }

    case uploadsConstants.CLOSE_UPLOAD:
      return { ...state, uploadModel: null }


    default:
      return state
  }
}