export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',


    DOWNLOAD_REQUEST: 'DOWNLOAD_FILE_REQUEST',
    DOWNLOAD_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
    DOWNLOAD_FAILURE: 'DOWNLOAD_FILE_FAILURE',

    CONTACT_REQUEST: 'CONTACT_SEND_REQUEST',
    CONTACT_SUCCESS: 'CONTACT_SEND_CONTACT_SUCCESS',
    CONTACT_FAILURE: 'CONTACT_SEND_CONTACT_FAILURE',

    VIDEO_REQUEST: 'VIDEO_UPLOAD_REQUEST',
    VIDEO_SUCCESS: 'VIDEO_UPLOAD_CONTACT_SUCCESS',
    VIDEO_FAILURE: 'VIDEO_UPLOAD_CONTACT_FAILURE',
    VIDEO_CHOICE_CHANGE: 'VIDEO_CHOICE_CHANGE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',
    COOKIE_MESSAGE_ACCEPT:'USERS_COOKIE_MESSAGE_ACCEPT',

    
};
