import React from 'react';
import { Link } from 'react-router-dom';

import { connect, } from 'react-redux';
import { downloadActions,alertActions } from '../../_actions';
import { QuickCms } from '../../_components/QuickCms';
import { config } from '../../_helpers';
import {getBlob  } from '../../_helpers/base64.js'
import defaultThumbnail from '../../assets/default-thumbnail.png';
import One2TenLogo from '../../assets/one2ten-logo.png'
import ReactPiwik from 'react-piwik';
import Banner from '../../assets/thumbs/Banner.jpg'
import Guidance from '../../assets/thumbs/Guidance.jpg'
import Invitation from '../../assets/thumbs/Invitation.jpg'
import Poster from '../../assets/thumbs/Poster.jpg'
import PPT from '../../assets/thumbs/PPT.jpg'

import gibson_video from '../../assets/thumbs/gibson_video.jpg'
import haas_video from '../../assets/thumbs/haas_video.jpg'
import kakkar_video from '../../assets/thumbs/kakkar_video.jpg'
import turpie_video from '../../assets/thumbs/turpie_video.jpg'
import global_ppt from '../../assets/thumbs/global_ppt.jpg'

class DownloadPage extends React.Component {

    componentDidMount(){
      this.bindDownloadLink();

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.file !== nextProps.file) {
        // this.downloadFile( nextProps.file);
         this.downloadFileByName( nextProps.file);
        }
        
      }
    bindDownloadLink(){
        var $this = this;
        $("[data-file]").click(function(e){
            e.preventDefault();
          var fileName=  $(this).attr("data-file");
         // $this.getFile(fileName);
         $this.getFileName(fileName);
        });

    }
   
    getFile(fileName){
   
            const { dispatch } = this.props;
            const { file } = this.props;
            dispatch(alertActions.request('Downloading..'));
            dispatch(downloadActions.geFile(fileName));
    }
    getFileName(fileName){
   
        const { dispatch } = this.props;
        const { file } = this.props;
        //dispatch(alertActions.request('Downloading..'));
        dispatch(downloadActions.getEncryptedFileName(fileName));
}
    downloadFile(file){
        var filename = file.filename
       var blob =file
       
     
        if(window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else{
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
        
         }
       
      
      
    }
    downloadFileByName(file){
      
            var elem = window.document.createElement('a');
            elem.href = config.apiUrl +"/download/getSecuredFile/?name="+file.name
            elem.download = file.filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
            this.submitAnalytics( file.filename);
         
       
      
      
    }
    submitAnalytics(filename){
        const { user } = this.props;
        var myevent = filename+" downloaded by user id:" + user.id;

        ReactPiwik.push(['trackEvent', 'File Download', myevent]);
    }
    render() {

        const { user } = this.props;
        const { file } = this.props;
        return (
            <div className="row">
            <div className="container download-toolkit-wrap">
          
            <div className="col-md-12">
            <QuickCms name="DownloadText">
                <h2>Download Toolkit</h2>
                <p>To help you celebrate the ‘Xarelto’ 10 Year anniversary the following toolkit of downloadable materials has been developed. Your activities can use the One2Ten logo, supporting graphic and video files, and templates for presentations, posters and other events. </p>
                <p>
                The Download Toolkit will be updated regularly as new materials become available throughout the year, in the run up to the One2Ten ‘Xarelto’ 10 Year Anniversary Town Hall in September.
                </p>
             
                </QuickCms>
                <h3>You can download the following:</h3>
               

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="row">
                           
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="'Xarelto' 10-Year Anniversary_Global Execution.pptx">
                                        <img src={global_ppt}/>
                                        <p>
                                        'Xarelto' 10-Year Anniversary_Global Execution (PPTX, 32MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="ASK-Xarelto_10-Year-Anniversary_TL_Michael_Gibson_Internal.mp4">
                                        <img src={gibson_video}/>
                                        <p>
                                        ‘Xarelto’_10-Year-Anniversary TL video – Michael Gibson (Internal) (MP4, 18MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="ASK-Xarelto_10-Year-Anniversary_TL_Ajay_Kakkar_Internal.mp4">
                                        <img src={kakkar_video}/>
                                        <p>
                                        ‘Xarelto’_10-Year-Anniversary TL video – Ajay Kakkar (Internal) (MP4, 27MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="ASK-Xarelto_10-Year-Anniversary_TL_Sylvia_Haas_Internal.mp4">
                                 
                                        <img src={haas_video}/>
                                        <p>
                                        ‘Xarelto’_10-Year-Anniversary TL video – Sylvia Haas (Internal) (MP4, 73MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="ASK-Xarelto_10-Year-Anniversary_TL_Graham_Turpie_Internal.mp4">
                                        <img src={turpie_video}/>
                                        <p>
                                        ‘Xarelto’_10-Year-Anniversary TL video – Graham Turpie (Internal) (MP4, 30MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Guidance_on_how_to_get_the_most_out_of_the_toolkit.pdf">
                                        <img src={Guidance}/>
                                        <p>
                                        Guidance on how to get the<br/> most out of the toolkit <br/>(PDF, 1.3MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Branding_elements.zip">
                                        <img src={One2TenLogo}/><br/>
                                     <p>
                                     Branding elements <br/>(AI,EPS 7.74MB)
                                        </p>
                                    </a>
                                    </div>
                                  
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Branding_elements.png">
                                        <img src={One2TenLogo}/>
                                        <p>
                                        Branding elements <br/>(PNG, 512KB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Anniversary_PowerPoint_Template.potx">
                                        <img src={PPT}/>
                                        <p>
                                        Anniversary PowerPoint template <br/>(POTX, 1.4MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Event_invitation_template.pdf">
                                        <img src={Invitation}/>
                                        <p>
                                        Event invitation template <br/>(PDF, 1.03MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Poster_design_files.zip">
                                        <img src={Poster}/>
                                        <p>
                                        Poster design files <br/>(ZIP, 7.3MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                    <div className="Toolkit-download">
                                    <a href="#" data-file="Pullup_banner_design_files.zip">
                                        <img src={Banner}/>
                                        <p>
                                        Pullup banner design files <br/>(ZIP, 7.6MB)
                                        </p>
                                    </a>
                                    </div>
                                </div>
                              
                               
                               
                               
                            </div>
                        </div>
                    </div>
            
            </div>
            </div>
            </div>
        )
    }


}

function mapStateToProps(state) {
    const { authentication,download } = state;
    const { user } = authentication;
    const { file } = download;
    return {
        user,file
    };
}

const connectedDownloadPage = connect(mapStateToProps)(DownloadPage);
export { connectedDownloadPage as DownloadPage };