
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from 'react-calendar';
import { videoActions, alertActions } from '../../_actions';
import { QuickCms } from '../../_components/QuickCms';

class UploadChooser extends React.Component {


    constructor(props) {
        super(props);

    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextprop) {

    }
    onFormTypeChanged(formType) {
     
        const { dispatch} = this.props;
        dispatch(videoActions.changeFormType(formType));
    }
    render() {
        const { formType} = this.props;
        return (
            <ul className="uploadChoiceList">
               <li className={formType=="story"? "active":""}> <a  onClick={() => this.onFormTypeChanged("story")}>Add Your Story to the Timeline</a></li>
               <li className={formType=="approval"? "active":""}> <a  onClick={() => this.onFormTypeChanged("approval")}>Add an Approval Date to the Timeline</a></li>
               <li className={formType=="hb"? "active":""}> <a  onClick={() => this.onFormTypeChanged("hb")}>Sing 'Happy Birthday' in Your Local Language</a></li>
            </ul>
        )
    }
}



function mapStateToProps(state) {
    const { users, authentication, video } = state;
    const { user } = authentication;
    const { formType } = video;
    return {
        formType,
        user
    };
}

const connectedUploadChooser = connect(mapStateToProps)(UploadChooser);
export { connectedUploadChooser as UploadChooser };