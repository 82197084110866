import React from 'react';
import { connect } from "react-redux"
import { config } from '../../_helpers';
import { uploadsActions } from '../../_actions';
import { getBase64 } from '../../_helpers/base64.js'
import moment from 'moment';
import 'moment/locale/en-gb';
import Calendar from 'react-calendar';
import { countryData, regionData } from'../../_helpers/data.js'

class Editor extends React.Component {

    constructor(props) {
        super(props);

        const { name, value, dispatch } = this.props;

        this.state = {
            showEditor: false,
            value: '',
        }

        this.showEditor = this.showEditor.bind(this);
        this.submitChange = this.submitChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        moment.locale('en-gb');
        this.setValue();
        
    }
    setValue() {

        const { name, value,type } = this.props;
       
            this.setState({ value: value });
    


    }
   
    showEditor(e) {
        e.preventDefault();
        const { type } = this.props;
        
        this.setState({ showEditor: true },() => {
            var uploadModel = this.props.uploadModel;
            uploadModel = {
                ...uploadModel,
                isEditing: true
            }
            if(type=='select'){
                this.bindDropdown();
            }
            this.props.dispatch(uploadsActions.storyItemChanged(uploadModel));
        });
      
    }
    bindDropdown() {

        const { name, value } = this.props;
       var data = this.getData(name);
       var element = $('[id="'+name+'"]');

        $.each(data, function (i, option) {
            var value = name =='uploadUser.country'?option.id:option.name;
            element.append($('<option/>').attr("value", value).text(option.name));
        });

        element.val(value);
     
    }
    getData(name){
        return   (name =='uploadUser.country') ? countryData: regionData;;
    }
    submitChange(e) {
        e.preventDefault();
       this.saveModel();
      
    }
    saveModel(){
        const { value } = this.state;
        const { name,type } = this.props;
      
        if (this.props.value == value) { 
             this.setState({ showEditor: false }); 
             var uploadModel = this.props.uploadModel;
             uploadModel = {
                 ...uploadModel,
                 isEditing: false}
                 this.props.dispatch(uploadsActions.storyItemChanged(uploadModel));
             return;
            }

        var uploadModel = this.props.uploadModel;
        uploadModel = this.setValueToModel(name,value);
        this.props.dispatch(uploadsActions.storyItemChanged(uploadModel));
        
        if(type =="file"){
            
            this.setBase64ToModel(name);
        }

        this.setState({ showEditor: false });
    }
    setBase64ToModel(name) {
      
       
        var elementId =name+'_file'
       
        var file  =  $('[id="'+elementId+'"]')[0].files[0];
    
        if (!file) { 

           this.setValueToModel(name+Base64,'')
           return; 
        }
        getBase64(file).then(
            data =>{
              
               var uploadModel = this.setValueToModel(name+"Base64",data)
               this.props.dispatch(uploadsActions.storyItemChanged(uploadModel));
            }
           
            );
    }
    setValueToModel(name,value){
        var uploadModel = this.props.uploadModel;
        var uploadUser = this.props.uploadModel.uploadUser;
       

        if(name.split('.')[0] == 'uploadUser'){
            uploadUser = {...uploadUser,[name.split('.')[1]]: value};
            uploadModel.uploadUser = uploadUser;
        }
        else{ 
          
            uploadModel = {...uploadModel,[name]: value};
            
        }
        uploadModel = {
            ...uploadModel,
            isDirty: true,
            isEditing: false
        } 
        return uploadModel;
    }
    handleChange(e) {
        e.preventDefault();
        var target = e.target;
        this.setState({ value: target.value },() =>{

            const {type } = this.props;
            if(type == 'file' || type=='cal'  || type=='select' || type=="file"){
                this.saveModel();
            }
        }
    
    );
     

    }
    handleDateChange(date) {
        const { name } = this.props;
        var momentObj = moment(date);
       
        this.setState({ value: momentObj.format() },() =>{
       
        $(".calendar").toggle();
        this.saveModel();
    }
    
);
 

    }
    showCalendar() {
        $(".calendar").toggle();

    }
  

    render() {
        const { showEditor, value } = this.state;
        const { type,name,uploadModel } = this.props;
        return (
          
            <div>
                {!showEditor &&  <div>{ (type == "cal")?moment(value).format("DD MMM YYYY"):value} { !uploadModel.isEditing && <a href="#" onClick={this.showEditor}><i className="fa fa-pencil"></i></a>} </div>}
                {showEditor && <div>
                    {type == 'text' &&
                        <input className="form-control" type="text" value={value} onChange={this.handleChange} />}
                    {type == 'textarea' &&
                        <textarea className="form-control message-field" value={value} onChange={this.handleChange} />}

                    {type == 'file' && <input className="form-control" type="file" id={name+'_file'} name={name+'_file'} onChange={this.handleChange} />}

                    {type == 'select' &&
                        <select className="form-control" id={name} name={name} value={value} onChange={this.handleChange} ><option value="">Select {(name.split('.')[1].lenght)?name.split('.')[1]:name}</option></select>
                    }
                     {type == 'cal' &&
                     <div>
                          <input type="text" id={name} className="form-control calendar-input" readOnly name={name}  onClick={this.showCalendar} value={moment(value).format('DD MMM YYYY')} />
                          <Calendar className="calendar" minDetail="year" view="month" onChange={this.handleDateChange} /></div>
                    }
                    <a href="#" onClick={this.submitChange}><i className="fa fa-check"></i></a></div>}
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { uploads, authentication } = state;
    const { user } = authentication;
    const { uploadModel } = uploads;

    return {
        user,
        uploads,
        uploadModel
    };
}

const connectedEditor = connect(mapStateToProps)(Editor);
export { connectedEditor as Editor };