import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history } from '../../_helpers';
import { QuickCms } from '../../_components/QuickCms';

class PrivacyStatementPage extends React.Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }
componentDidMount(){
    if(history.location.hash){
            var target= $(history.location.hash);
            
            $('html,body').animate({
                scrollTop: target.offset().top
            }, 1000);
           
    }
}
goBack(e) {
    e.preventDefault();
    this.props.history.goBack();
}


    render() {
        const { user } = this.props;
        return (
            <div className="row">
                <div className="container footer-page">
                    <div className="col-sm-12">
                        <br />
                        <Link to="#" onClick={this.goBack}>❮ back </Link>
                        <QuickCms name="GeneralConditionsText">
                            <h2>Privacy Statement</h2>
                            <p>
                                Bayer AG is aware that the security of your private information from the use of our website is an important concern. We take the protection of your personal data very seriously. Therefore we would like you to know what data we maintain and what data we discard. With this privacy statement, we would like to inform you about our security measures.
         </p>
                        </QuickCms>
                        <QuickCms name="CollectionOfDataText">
                            <h2>Collection of Data</h2>
                            <p>
                                You can use our website without disclosing your personal data. You are not required to provide personal information as a condition of using our site, except as may be necessary to provide you a product or service at your request. When you use our website, data may be stored for various security purposes. This data may include the name of your internet service provider, the website that you used to link to our site, the websites that you visit from our site and your IP-Address. This data could possibly lead to your identification, but we do not use it to do so. We do use the data from time to time for statistical purposes and we may use analytical tool software for evaluation purposes, but maintain the anonymity of each individual user so that we will not try to identify the person. In cases when personal data is provided to others to provide you products or services you have requested, or for other purposes you have authorized, we rely on technical and organizational means to assure that applicable data security regulations are followed.
         </p>
                        </QuickCms>
                        <QuickCms name="CollectionAndProcessingText">
                            <h2>Collection and Processing of Personal Data</h2>
                            <p>
                                We collect personal data only when you provide it to us, through registration, completion of forms or e-mails, as part of an order for products or services, inquiries or requests about materials being ordered and similar situations in which you have chosen to provide the information to us.
         </p>
                            <p>
                                The database and its contents remain at our company and stay with data processors or servers acting on our behalf and responsible to us. Your personal data will not be passed on by us or by our agents for use by third parties in any form whatsoever, unless we have obtained your consent or are legally required to do so. In case we may use analytical tool software for evaluation purposes the results of such evaluation may be shared with third parties but without disclosing the identity of a person.
         </p>
                            <p>
                                We will retain control of and responsibility for the use of any personal data you disclose to us. Some of this data may be stored or processed at computers located in other jurisdictions, such as the United States, whose data protection laws may differ from the jurisdiction in which you live. In such cases, we will ensure that appropriate protections are in place to require the data processor in that country to maintain protections on the data that are equivalent to those that apply in the country in which you live.
         </p>
                        </QuickCms>
                        <QuickCms name="PurposesOfUseText">
                            <h2>Purposes of Use</h2>
                            <p>
                                The data we collect will only be used for the purpose of supplying you with the requested products or services or for other purposes for which you have given your consent, except where otherwise provided by law.
         </p>
                        </QuickCms>
                        <QuickCms name="RightOfAccessText">
                            <h2>
                                Right of Access and Correction
         </h2>
                            <p>
                                You have the right to review and amend any personal data stored in our system if you believe it may be out of date or incorrect. Just send an e-mail to the address given in the imprint or contact the Privacy Officer at the address below.
         </p>
                        </QuickCms>
                        <QuickCms name="RightOfCancellationText">
                            <h2>
                                Right of Cancellation
         </h2>
                            <p>
                                You have the right at any time to withdraw your consent to the use of your personal data in the future. Again, just send an e-mail to the address given in the imprint or contact the Data Security Officer at the address below.
         </p>
                        </QuickCms>
                        <QuickCms name="RightOfCancellationText">
                            <h2>
                                Data Retention
         </h2>
                            <p>
                                We only retain personal data for as long as is necessary for us to render a service you have requested or to which you have given your consent, except where otherwise provided by law (e.g. in connection with pending litigation).
         </p>
                        </QuickCms>
                        <QuickCms name="UseofCookiesText">
                            <h2>
                                Use of Cookies
         </h2>
                            <p>
                                This website uses so-called <strong>"Cookies"</strong>. Cookies are small text files that are stored on your hard drive via your browser. They store certain information (e.g. your preferred language or site settings) that your browser might retransfer to us upon your next access to our website (depending on the cookie’s lifespan).
         </p>
                            <p>
                                We use two categories of Cookies: (1) Cookies required for technical purposes, without which the functionality of our website would be reduced, and (2) Optional Cookies:
         </p>
                        </QuickCms>
                        <QuickCms name="FunctionalCookiesText">
                            <h2 className="hidden-xs"><i>Functional cookies</i></h2>
                            <table className="table hidden-xs">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Cookie name</th>
                                        <th scope="col">Lifespan</th>
                                        <th scope="col">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>MCHECKED</td>
                                        <td>End of session</td>
                                        <td>Encrypted information, containing end user's browser capabilities</td>
                                    </tr>
                                    <tr>
                                        <td>PHPSESSID</td>
                                        <td>1 hour</td>
                                        <td>PHP data storage identifier, set when using PHP session() method</td>
                                    </tr>
                                    <tr>
                                        <td>UniqueID</td>
                                        <td>6 months</td>
                                        <td>The anonymous identification of the duration of the visit for statistical analysis in order to optimize the website. No personal data is collected.
                  </td>
                                    </tr>
                                    <tr>
                                        <td>WHGACE</td>
                                        <td>1 day</td>
                                        <td>Load balancer cookie to ensure correct website behaviour</td>
                                    </tr>
                                    <tr>
                                        <td>WHGCOOKIECONSENT</td>
                                        <td>6 months</td>
                                        <td>	Remember decision on use of optional Cookies:
                                           Saves your decision to either accept or decline the use of optional Cookies. No personal data is collected in this Cookie.
                  </td>
                                    </tr>
                                    <tr>
                                        <td>WHGUETTR</td>
                                        <td>1 day</td>
                                        <td>Identify user anonymously for statistical purposes and personalization</td>
                                    </tr>
                                    <tr>
                                        <td>loggedin, userxa</td>
                                        <td>Duration of the session: the cookie is deleted when the browser is closed.</td>
                                        <td>The anonymous identification of a session (website visit) for technical purposes only. No personal data is collected.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </QuickCms>
                        <a id="performanceCookies"></a>
                        <QuickCms name="PerfomanceCookiesText">
                            <h2 className="hidden-xs"><i>Performance cookies</i></h2>
                            <table className="table performance hidden-xs">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Cookie name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Consent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Google Tag Manager</td>
                                        <td>Google Tag Manager uses first-party cookies to distinguish unique users and throttle the request rate.
                                           Lifespan: 6 months
                                           Cookie provider: Google Inc.
                  </td>
                                        <td>
                                            <span className="tick">✔</span>
                                            <span className="wrong">╳</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Visual Website Optimizer</td>
                                        <td>Our website uses a testing tool from Visual Website Optimizer (http://visualwebsiteoptimizer.com) to determine specific statistical values regarding the use of our services. The tool anonymously collects measured data. This test uses cookies to recognise computer systems. The run time of these cookies is limited to the duration of the test. IP addresses are not saved in the process. You can opt out of having your anonymously collected data stored in the future at any time. (http://visualwebsiteoptimizer.com/opt-out.php)
                                           Lifespan: 1 year
                                           Cookie provider: visualwebsiteoptimizer.com
                  </td>
                                        <td>
                                            <span className="tick">✔</span>
                                            <span className="wrong">╳</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Webtrends</td>
                                        <td>Webtrends uses third-party cookies to distinguish unique users and throttle the request rate.
                                           Lifespan: 2 years
                                           Cookie provider: Webtrends Inc.
                  </td>
                                        <td>
                                            <span className="tick">✔</span>
                                            <span className="wrong">╳</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <p>
                                We will not use Optional Cookies without your prior consent. Upon your first access to our website, a banner will appear on our website, asking you to give us your consent to the use of Optional Cookies. If your consent is given, we will set a Cookie on your computer and the banner is not shown to you anymore for the lifespan of that Cookie. After such period, or if you actively delete the Cookie, the banner will appear again the next time you visit our website and you will again be asked to give your consent.
         </p>
                            <p>
                                You are able to withdraw your consent given to the use of Optional Cookies at any time by changing the respective option in the table above. Of course you may use our website without any Cookies being used at all (including Cookies for technical purposes). In your browser, you may at any time restrict or deactivate the use of Cookies. This may, however, have a certain negative impact on the functionality and user-friendliness of our site.
         </p>
                        </QuickCms>
                        <QuickCms name="WebAnalysisText">
                            <h2>
                                Web analysis
         </h2>
                            <p>
                                Our website uses Google Analytics, a web analysis service of Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States <strong>("Google").</strong>
                            </p>
                            <p>
                                Based on your prior consent, Google will analyse your use of our website on our behalf. To this purpose, we use, among others, the Cookies described in more detail in the above table. The information collected by Google in connection with your use of our website (e.g. the referring URL, which of our webpages you visit, your browser type, your language settings, your operating system, your screen resolution) will be transmitted to a server of Google in the US, where it will be stored and analysed; the respective results will then be made available to us in an anonymised form. In this process, your usage data will not be connected to your full IP address. We have activated on our website the IP anonymising function offered by Google, so that the last 8 digits (type IPv4) or the last 80 bits (type IPv6) of your IP address are deleted. Moreover, Google is certified under the EU-US <a>Privacy Shield</a>, which ensures an adequate level of data protection with respect to the processing of data by Google in the US.
         </p>
                            <p>
                                You may withdraw your consent to the use of web analysis at any time either by downloading and installing a <a>Browser Plugin</a> offered by Google or by managing your consents in the table <a>above</a>, whereby an opt-out cookie will be set. Both options will prevent the use of web analysis only if you use the browser on which you installed the plugin or if you do not delete the opt-out Cookie.
         </p>
                            <p>
                                For further information on Google Analytics please refer to the <a>Google Analytics Terms of Service</a>, the <a>Google Analytics security and privacy principles</a>, and the <a>Google Privacy Policy</a>.
         </p>
                        </QuickCms>
                        <QuickCms name="UseOfVisualText">
                            <h2>
                                Use of Visual Website Optimizer
         </h2>
                            <p>
                                Our website uses a testing tool from Visual Website Optimizer (http://visualwebsiteoptimizer.com) to determine specific statistical values regarding the use of our services. The tool anonymously collects measured data. This test uses cookies to recognise computer systems. The run time of these cookies is limited to the duration of the test. IP addresses are not saved in the process. You can opt out of having your anonymously collected data stored in the future at any time. (http://visualwebsiteoptimizer.com/opt-out.php)
         </p>
                        </QuickCms>
                        <QuickCms name="PrivacySecurityText">
                            <h2>Security</h2>
                            <p>
                                Bayer AG uses technical and organizational security precautions to protect your data from manipulation, loss, destruction or access by unauthorized persons. Any personal data that is provided to Bayer AG by you will be encrypted in transit to prevent its possible misuse by third parties. Our security procedures are continuously revised based on new technological developments.
         </p>
                        </QuickCms>
                        <QuickCms name="PrivacyChildrenText">
                            <h2>
                                Children
         </h2>
                            <p>
                                In light of the importance of protecting children's privacy, we do not collect, process or use on our website any information relating to an individual whom we know to be under 13 years old without the prior, verifiable consent of his or her legal representative. Such legal representative has the right, upon request, to view the information provided by the child and/or to require that it be deleted.
         </p>
                        </QuickCms>
                        <QuickCms name="PrivacyContactsText">
                            <h2>
                                Contacts
         </h2>
                            <p>
                                Please contact the Corporate Data Protection Officer with any problems, questions or suggestions that you may have. The constant development of the internet requires occasional adjustments to our privacy statement. We retain the right to make changes when necessary.
                                If you have any problems, questions or ideas, please contact one of the following persons.
         </p>
                        </QuickCms>
                        <QuickCms name="DataPrivacyManagerText">
                            <h2>Data Privacy Manager of Bayer AG</h2>
                            <ul>
                                <li>Fatih Ataoglu</li>
                                <li>Bayer AG</li>
                                <li>Law, Patents & Compliance</li>
                                <li>Building Q26</li>
                                <li>51368 Leverkusen, Germany</li>
                                <li>Phone: +49 (0) 214 30 450 95</li>
                                <li>E-mail</li>
                            </ul>
                        </QuickCms>
                        <QuickCms name="HeadOfDataText">
                            <h2>Head of Data Privacy of Bayer AG</h2>
                            <ul>
                                <li>Eva Gardyan-Eisenlohr</li>
                                <li>Müllerstr. 178,</li>
                                <li>13353 Berlin, Deutschland</li>
                                <li>Phone: +49 (0) 30 468 16924</li>
                                <li>E-mail</li>
                            </ul>
                        </QuickCms>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}
const connectedPrivacyStatementPage = connect(mapStateToProps)(PrivacyStatementPage);
export { connectedPrivacyStatementPage as PrivacyStatementPage };