import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { QuickCms } from '../../_components/QuickCms';

class ConditionsOfUsePage extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    render() {
        const { user } = this.props;
        return (
            <div className="row">
                <div className="container footer-page">
                    
                        <div className="col-sm-12"><br />
                        <Link to="/" onClick={this.goBack}>❮ back </Link>
                        </div>
                   

                  
                        <div className="col-sm-9">
                            <QuickCms name="GeneralConditionsText">

                                <h2>General Conditions of use</h2>
                                <p>
                                    Access to and use of this website are subject to the following conditions. Please do not use this website unless you agree with these conditions. This website has been developed by the Bayer AG, Pharmaceuticals, Business Unit General Medicine (hereinafter referred to as BAYER) and is administrated by the same. We reserve the right to discontinue or to make partial or complete modifications to this website or to the General Conditions of Use, to our General Terms and Conditions, and to our Conditions of Sale and Delivery. Please note that we may make such changes at our own discretion and without prior announcement. We must therefore ask you, next time you visit this website, to view the conditions again and to note any changes or amendments that may have been made.
            </p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisised do eiusmod tempor incididunt ut.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisised do eiusmod tempor incididunt ut.
            </p>
                            </QuickCms>

                        </div>
                        <div className="col-sm-3 info-box">
                            <QuickCms name="BleedingManagementText">
                                <h2> Bleeding Management</h2>
                                <p>
                                    All anticoagulant therapy carries a risk of bleeding. Review practical guidance on how these can be managed.
            </p>
                                <a>Find out more</a>
                            </QuickCms>
                        </div>
                    
                        <div className="col-sm-9">
                            <QuickCms name="SurrenderofUseText">
                                <h2>Surrender of use and benefit</h2>
                                <p>
                                    All details, documents and illustrations published on this website are the sole property of BAYER. Any permission to use the same is granted on the proviso that the relevant copyright note is displayed on all copies, that such details are only used for personal purposes, that they are not exploited commercially, that the details are not modified in any way and that all illustrations gained from the website are only used in conjunction with the accompanying text.
            </p>
                            </QuickCms>
                            <QuickCms name="TrademarksText">
                            <h2>Trademarks and Copyright</h2>
                            <p>
                                All trademarks on this website are the property of the Bayer Group, unless otherwise noted or in any other way perceivable as third party rights. Any unauthorized use of these trademarks or other materials is expressly prohibited and constitutes a violation of copyright, trademark law or other industrial property rights.
            </p>
            </QuickCms>
            <QuickCms name="LimitedLiabilityText">
                            <h2>Limited Liability</h2>
                            <p>
                                BAYER has compiled the detailed information provided on this website from internal and external sources to the best of its knowledge and belief, using professional diligence. We endeavor to expand and update this range of information on an ongoing basis. The information on this website is purely for the purpose of presenting BAYER and its products and services. However, no representation is made or warranty given, either expressly or tacitly, for the completeness or correctness of the information on this website. <strong>Please be aware that this information although accurate on the day it was published may no longer be up to date</strong>. We therefore recommend that you check any information you obtain from this website prior to using it in whatever form. Advice given on this website does not exempt you from conducting your own checks on our latest advice - particularly our safety datasheets and technical specifications - and on our products, with a view to their suitability for the intended processes and purposes. Should you require any advice or instructions concerning our products or services, please contact us directly. Users of this website declare that they agree to access the website and its content at their own risk. Neither BAYER nor third parties involved in the writing, production or transmission of this website can be held liable for damage or injury resulting from access or the impossibility of access or from the use or impossibility of use of this website or from the fact that you have relied on information given on this website.
            </p>
            </QuickCms>
            <QuickCms name="WebsitesText">
                            <h2>Websites of third-party vendors/links</h2>
                            <p>
                                This website contains links/references to third-party websites. By providing such links, BAYER does not give its approval to their contents. Neither does BAYER accept any responsibility for the availability or the contents of such websites or any liability for damage or injury resulting from the use of such contents, of whatever form. <strong>BAYER offers no guarantee that pages linked to provide information of consistent quality</strong>. Links to other websites are provided to website users merely for the sake of convenience. Users access such websites at their own risk. <strong>The choice of links should in no way restrict users to the linked pages</strong>.
            </p>
            </QuickCms>
            <QuickCms name="DetailsSuppliedText">
                            <h2>Details supplied by yourself</h2>
                            <p>
                                The user of this website is fully responsible for the content and correctness of details he or she sends to BAYER as well as for the non-violation of any third-party rights that may be involved in such details. The user gives his or her consent for BAYER to store such details and to use the same for the purpose of statistical analysis or for any other specified business purpose, unless the information involves personal details, going beyond master data or usage data as defined in sections 14 and 15 of the German Tele Media Act (Telemediengesetz). In particular, BAYER is entitled to use the contents of such messages, including ideas, inventions, blueprints, techniques and expertise contained therein, for any purpose, such as the development, production and/or marketing of products or services and to reproduce such information and make it available to third parties without any limitations.
            </p>
            </QuickCms>
            <QuickCms name="InternationUsersText">
                            <h2>International users</h2>
                            <p>
                                This website is checked, operated and updated by BAYER at Berlin, Germany. It is intended for international use. BAYER gives no guarantee that the details presented on this website are also correct worldwide and, in particular, that products and services will be available with the same appearance, in the same sizes or on the same conditions. Should you call up this website download contents, please note that it is your own responsibility to ensure that you act in compliance with local legislation applicable in that place. In the USA the business of the Bayer Group is conducted by Bayer Corporation. Customers in the USA are requested to address to one of these entities.
            </p>
            </QuickCms>
            <QuickCms name="ApplicableLawText">
                            <h2>Applicable law</h2>
                            <p>
                                Any legal claims or lawsuits in conjunction with this website or its use are subject to the interpretation of the laws of the Federal Republic of Germany, except for the provisions of international private law and the Hague Convention relating to a Uniform Law on the International Sale of Goods of July 1, 1964 and in the UN Sales Convention of April 11, 1980.
            </p>
            </QuickCms>
            <QuickCms name="ForwardLookingText">
                            <h2>Forward-Looking Statements</h2>
                            <p>
                                This website may contain forward-looking statements based on current assumptions and forecasts made by Bayer Group or subgroup management. Various known and unknown risks, uncertainties and other factors could lead to material differences between the actual future results, financial situation, development or performance of the company and the estimates given here. These factors include those discussed in Bayer’s public reports which are available on the Bayer website at   <a href="http://www.bayer.com">www.bayer.com.</a> The company assumes no liability whatsoever to update these forward-looking statements or to conform them to future events or developments.
            </p>
            </QuickCms>
                        </div>
                    </div>
                </div>
            
        )
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}
const connectedConditionsOfUsePage = connect(mapStateToProps)(ConditionsOfUsePage);
export { connectedConditionsOfUsePage as ConditionsOfUsePage };