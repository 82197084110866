import { timelineConstants } from '../_constants';

export function timeline(state = {}, action) {
  switch (action.type) {
    case timelineConstants.GET_TIMELINE_REQUEST:
      return {
        loading: true
      };
    case timelineConstants.GET_TIMELINE_SUCCESS:
      return {
        item: action.timeline
      };
    case timelineConstants.GET_TIMELINE_FAILURE:
      return {
        error: action.error
      };
      case timelineConstants.TIMELINE_LOADED_SUCCESS:
      return {
        timeline_loaded: true
      };
      default:
      return state
  }
}