export const getBase64= ( file)  =>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

export const getBase64ContentType= (base64String)  =>{

    var contentType = base64String.split(',')[0].replace('data:','').replace(';base64','');
    
    return contentType;
}

export const getBase64Data= ( base64String)  =>{
    var data =  base64String.split(',')[1];
 
 return data;
 
}

export const getBlob = (base64String) =>{

    var b64toBlob = require('b64-to-blob');
    var base64ContentType = getBase64ContentType(base64String);
    var base64Data = getBase64Data(base64String);

    var contentType = base64ContentType;
    var baserdData = base64Data
    var blob = b64toBlob(base64Data, contentType);
 

    return blob;
}