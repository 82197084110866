import { authHeaderjSON, config } from '../_helpers';

export const downloadService = {
    getFile,
    getEncryptedFileName
    
};

function getFile(fileName) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(fileName),
       
    };
  
    return fetch(config.apiUrl + '/download/getFile', requestOptions)
       // .then(handleResponse, )
        .then(response => {
            return response.blob();
        },handleError).then(blob => {
            return blob;
        });
        
}
function getEncryptedFileName(name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(name),
       
    };
  
    return fetch(config.apiUrl + '/download/getEncryptedFileName', requestOptions)
    .then(handleResponse, handleError) .then(file => {
        return file;
        });;
        
}


function handleResponse(response) {
    return new Promise((resolve, reject) => {
        
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}