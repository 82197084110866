import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import ReactPiwik from 'react-piwik';
export const userActions = {
    login,
    logout,
    authenticateToken,
    acceptCookiMessage
  
};

function login(username, password) {
    return dispatch => {
     

       fetchLogin(username, password, dispatch);
    };

   
}
function fetchLogin(username, password,dispatch){
    dispatch(request({ username }));
    return userService.login(username, password)
    .then(
        user => { 
            dispatch(success(user));
            redirectFromLogin();
            ReactPiwik.push(['trackEvent', 'Local Admin Login', "Success for user id: "+user.id]);
        },
        error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            ReactPiwik.push(['trackEvent', 'Local Admin Login', "Failed reason:"+error]);
        }
    );
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function redirectFromLogin(){
    var returnUrl =  localStorage.getItem('returnUrl')
   
    if(returnUrl !=null && returnUrl !=""){
       
        history.push(returnUrl);
        localStorage.setItem('returnUrl', "")
    }
    else{
        history.push('/');
    }
  
}
function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function authenticateToken(token) {
    return dispatch => {
        dispatch(request(token));
        
        userService.authenticateToken(token)
            .then(
                user => { 
                    dispatch(success(user));
                  
                    redirectFromLogin();
                    ReactPiwik.push(['trackEvent', 'SSO login success', "Success for user id: "+user.id]);
                 
                   
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    ReactPiwik.push(['trackEvent', 'SSO login failed', "Failed for token: "+token+" reason: "+error]);
                }
            );
    };

    function request(token) { return { type: userConstants.REGISTER_REQUEST, token } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, token } }
}


function acceptCookiMessage() {
    return dispatch => {
        localStorage.setItem('showCookieMessage', 'no');
        dispatch(success());
     
    };
    function success() { return { type: userConstants.COOKIE_MESSAGE_ACCEPT } }

   
}
