import { userConstants } from '../_constants';

export function download(state = {}, action) {
  switch (action.type) {
    case userConstants.DOWNLOAD_REQUESTT:
      return { sending: true };
    case userConstants.DOWNLOAD_SUCCESS:
      return {  file: action.file};
    
    case userConstants.DOWNLOAD_FAILURE:
      return {};
    default:
      return state
  }
}