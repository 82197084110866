import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { contact } from './contact.reducer';
import { video } from './video.reducer';
import { alert } from './alert.reducer';
import { download } from './download.reducer';
import { content } from './content.reducer';
import { editmode } from './editmode.reducer';
import { uploads } from './uploads.reducer';
import { timeline } from './timeline.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  contact,
  video,
  alert,
  download,
  content,
  editmode,
  uploads,
  timeline
});

export default rootReducer;