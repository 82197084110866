import { authHeaderjSON, config } from '../_helpers';

export const uploadsService = {
    getAllUploads,
    getFile,
    deleteStory,
    approveStory,
    editStory,
    uploadApproval
};


function getAllUploads() {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON() 
    };
  
    return fetch(config.apiUrl + '/upload/getAllUploads', requestOptions)
        .then(handleResponse, handleError) .then(content => {

            return content;
            });;
       
}

function deleteStory(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(id),
    };
  
    return fetch(config.apiUrl + '/upload/deleteUpload', requestOptions)
        .then(handleResponse, handleError) .then(message => {
            return message;
            });;
       
}
function approveStory(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(id),
    };
  
    return fetch(config.apiUrl + '/upload/approveUpload', requestOptions)
        .then(handleResponse, handleError) .then(message => {
            return message;
            });;
       
}
function editStory(story) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(story),
    };
  
    return fetch(config.apiUrl + '/upload/updateStory', requestOptions)
        .then(handleResponse, handleError) .then(message => {
            return message;
            });;
       
}
function getFile(uploadId,filetype) {
   
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify({uploadId:uploadId,fileType:filetype}),
       
    };
  
    return fetch(config.apiUrl + '/upload/getFile', requestOptions)
 //   .then(handleResponse, handleError);
        .then(response => {
             var contentType = response.headers.get("content-type");
            return response.blob();
        });
        
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}

function uploadApproval(model) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(model), 
     
    };
    return fetch(config.apiUrl + '/upload/AddApprovalDate', requestOptions)
        .then(handleResponse, handleError)
        .then(model => {
        });
}
