import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QuickCms } from '../../_components/QuickCms';
import { Quiz } from '../../_components/Quiz/Quiz';
class QuizPage extends React.Component {


    constructor(props) {
        super(props);
        
    }
   
    render() {
        const { user } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Quiz />
                    </div>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    
    return {
        user
    };
}

const connectedQuizPage = connect(mapStateToProps)(QuizPage);
export { connectedQuizPage as QuizPage };