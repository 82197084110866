import { quickCmsConstants } from '../_constants';

export function editmode(state = {}, action) {
    switch (action.type) {
      case quickCmsConstants.QUICKCMS_SET_EDITMODE_ON:
      return {isInEditMode: action.isInEditMode };
     case quickCmsConstants.QUICKCMS_SET_EDITMODE_OFF:
      return {isInEditMode: action.isInEditMode };
      default:
        return state
    }
  }