import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { config,history } from '../../_helpers';
import { QuickCmsEditMode } from '../../_components/QuickCmsEditMode';
import bayerLogoPng from '../../assets/bayer_logo.png';
import { connect } from 'react-redux';

class Header extends React.Component {

  constructor(props){
    super(props);
    history.listen((location,action) => {

      var menu = $("#navbar-collapse");
      menu.removeClass("active");
    
  });
  }

  getSelected(pathname){
    
    return this.props.location == pathname ? 'selected' : '';
  }
  componentDidMount(){
    $(".page-wrap").click(function(e){

      $("#navbar-collapse").removeClass("active");

    })
  }
  toggleMenu(event){
    event.preventDefault();
    $("#navbar-collapse").toggleClass("active");
  }

  

  

 render(){
   const {user,editmode} = this.props;
   
    return (
   
      <div id="header">
    
        <div className="container">  
      
          <div className="row container">
         
            <div className="col-sm-12 hidden-xs">
              {user &&
              <ul className="top-nav">
                <li><a href="http://www.bayer.com" target="_blank">Bayer Global</a></li>
                <li>
                  <Link to="/contact">
                    Contact us</Link>
                </li>
                <li>
                  <Link to="/help">
                    Help</Link>
                </li>
                <li className={this.getSelected('/login')}>
                  <Link to="/login">
                    Log out</Link>
                </li>
                <li className="admin-switch"> <div >{user && <QuickCmsEditMode />}</div></li>
              </ul>
              }
            </div>
          </div>
          <div className="row container hidden-xs">
            <div className="col-sm-10">
              <h1><Link to="/">Xarelto<sup>&reg;</sup> <span>10-Year Anniversary</span></Link></h1>
            </div>
            
            <div className="col-sm-2">
            <a href="http://www.bayer.com" target="_blank">
              <div className="bayerLogo right"></div>
              </a>
            </div>
          </div>
        </div>
        <div className="row  nav-bar hidden-xs">
          <div className="container">
            <div className="col-sm-12 ">
            {user &&
              <ul>
                <Link to="/">
                  <li className={this.getSelected('/')}>Home</li>
                </Link>
                <Link to="/downloads">
                  <li className={this.getSelected('/downloads')}>Download Internal Toolkit</li>
                </Link>
                <Link to="/external-downloads">
                          <li className={this.getSelected('/external-downloads')}>Download External Toolkit</li>
                        </Link>
                        <Link to="/townhall-downloads">
                          <li className={this.getSelected('/townhall-downloads')}>Download Townhall Toolkit</li>
                        </Link>
                <Link to="/upload">
                  <li className={this.getSelected('/upload')}>Add Your Story</li>
                </Link>
                {user && user.isAdmin && editmode.isInEditMode &&
                <Link to="/allstories" className="right">
                          <li className={this.getSelected('/allstories')}>All Stories</li>
                        </Link>
                }
                <Link to="/quiz">
                  <li className={this.getSelected('/quiz')}>Take Quiz</li>
                </Link>
              </ul>
            
            }
            
            </div>
            
  
          </div>
        </div>
        <div className="nav-bar visible-xs">
          <div className="container">
            <div className="col-sm-12">
              <nav className="navbar navbar-inverse navbar-static-top" role="navigation">
                <div className="navbar-header row">
                  <div className="col-sm-12 col-xs-12 left">
                    <div className="row">
                          <div className="col-sm-12 col-xs-12 padding-0">
                          {user &&
                            <ul className="top-nav">
                              <li><a href="http://www.bayer.com">Bayer Global</a></li>
                              <li>
                                <Link to="/contact">
                                  Contact us</Link>
                              </li>
                              <li>
                                <Link to="/help">
                                  Help</Link>
                              </li>
                              <li className={this.getSelected('/login')}>
                                <Link to="/login">
                                  Log out</Link>
                              </li>
                            </ul>
                          }
                  </div>
                    </div>
                    <div className="row">
                    
                    <div className="col-sm-2 col-xs-2 padding-0">
                    {user &&
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" onClick={this.toggleMenu} data-target="#navbar-collapse">
                          <span className="glyphicon glyphicon-menu-hamburger"></span>
                        </button>
                    }
                      </div>
                      <div className="col-sm-8 col-xs-8 padding-0">
                        <h1><Link to="/">Xarelto <span>10-Year Anniversary</span></Link></h1>
                      </div>

                      <div className="col-sm-2 col-xs-2 padding-0">
                          <a href="http://www.bayer.com">
                              <div className="bayerLogo"></div>
                          </a>
                      </div>
                    </div>
                    
                  </div>
                 
  
                </div>
  
  
  
                <div className="row">
                  <div className="col-sm-12">
                    <div className="collapse navbar-collapse" id="navbar-collapse">
                      <ul>
                        <Link to="/">
                          <li className={this.getSelected('/')}>Home</li>
                        </Link>
                        <Link to="/downloads">
                          <li className={this.getSelected('/downloads')}>Download Internal Toolkit</li>
                        </Link>
                        <Link to="/external-downloads">
                          <li className={this.getSelected('/external-downloads')}>Download External Toolkit</li>
                        </Link>
                        <Link to="/townhall-downloads">
                          <li className={this.getSelected('/townhall-downloads')}>Download Townhall Toolkit</li>
                        </Link>
                       
                        <Link to="/upload">
                          <li className={this.getSelected('/upload')}>Add Your Story</li>
                        </Link>
                        <Link to="/quiz">
                          <li className={this.getSelected('/quiz')}>Take Quiz</li>
                        </Link>
                        <li className="admin-switch"> <div >{user && <QuickCmsEditMode />}</div></li>
                        {user && user.isAdmin && editmode.isInEditMode &&
                
                         <Link to="/allstories">
                          <li className={this.getSelected('/allstories')}>All Stories</li>
                        </Link>
                       
                        }
                      </ul>
                    </div>
                  </div>
                </div>
  
  
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
 
};


function mapStateToProps(state) {
  const { authentication, editmode} = state;
  const { user } = authentication;
  return {
      user, 
      editmode 
    };
}

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header };
