import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Answer extends Component {
  constructor(props){
    super(props);
    this.state = {
        activeClass: ""
    }
  }
  componentDidMount(){
    setTimeout(() => {
      this.setState({
        activeClass: "active"
      });
    }, 100);
  }
  render() {
    const {userAnswers, answers, questionType, renderInResult, correctAns, qIdx, } = this.props;
    const {activeClass} = this.state;
    const renderAnswer = 
      answers.map((answer, index)=>{
        let value = answer;
        let btnClass = userAnswers && userAnswers[qIdx] && userAnswers[qIdx].includes(String(index + 1))?"btn-selected":"";

        return (
          ( questionType=='text' && (<button className={`${btnClass} btn-large`} key={index} data-value={answer} onClick={() => this.props.handleClick(index)}>{value}</button>) ) ||
          ( questionType=='photo' && (<button><img key={index} alt="" src={answer} onClick={() => this.props.handleClick(index)}/></button>) )
        )
      })
    
    const renderAnswerInResult = 
    renderInResult &&
      answers.map((answer, index)=>{
        let c = ""
        if(correctAns[qIdx].includes(String(index+1) )){
          c = "btn-success"
        }else if( !correctAns[qIdx].includes(String(index+1) ) && ((userAnswers[qIdx].includes(String(index+1))))){
          c = "btn-danger"
        }
         let value = answer
        return (
          ( questionType=='text' && (<button className={`${c} btn-large`} key={index} data-value={answer}>{value}</button>) ) ||
          ( questionType=='photo' && (<button className={`${c} img-answer`}><img key={index} alt="" src={answer}/></button>) )
        )
      })

    return (
      
      <div className={`answer-container row ${activeClass}`}>
        <div className="col-lg-3 col-md-3 col-sm-3 hide-in-results">
        <div className="fireworks ">
          <img className="fireworks-4" src="/assets/firework-03-3f553b3239a30a313b1f1b8d5f564d8e.png"/>
        </div>
       </div>
        <div className="col-lg-6 col-md-6 col-sm-6 ">
          {renderInResult == true? (<div className="result-answer">{renderAnswerInResult}</div>) : (<div>{renderAnswer}</div>) }
        </div>
      <div className="col-lg-3 col-md-3 col-sm-3 ">
    
      <div className="fireworks ">
          <img className="fireworks-1" src="/assets/firework-03-3f553b3239a30a313b1f1b8d5f564d8e.png"/>
          <img className="fireworks-2" src="/assets/firework-03-3f553b3239a30a313b1f1b8d5f564d8e.png"/>
          <img className="fireworks-3" src="/assets/firework-03-3f553b3239a30a313b1f1b8d5f564d8e.png"/>
      </div>
        
      </div>
       
       
      </div>
    );
  }
}

Answer.propTypes = {
  userAnswers: PropTypes.array,
  answers: PropTypes.array,
  questionType: PropTypes.string,
  renderInResult: PropTypes.bool,
  correctAns: PropTypes.array,
  qIdx: PropTypes.number,
  handleClick: PropTypes.func
};

export default Answer;


