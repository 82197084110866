import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { config, history } from '../../_helpers';
import { QuickCms } from '../../_components/QuickCms';
import { connect } from 'react-redux';
import { quickCmsActions } from '../../_actions';
class News extends React.Component {

    constructor(props) {
        super(props);
       
        this.showNew = this.showNew.bind(this);
    }

    componentDidMount() {

    }
    showNew(){
        this.props.dispatch(quickCmsActions.showNew());
    }
    fileterNewsContent(contentArr){
       
        var result = {};
        var content = [];
        var items = contentArr.items;
        for (var i in items) {
         
            var item = items[i];
         
            if (item.contentPage == "homeNews") {
           
                content.push(item);
            }
        } 
     
        return content;
    }
    render() {


        var count = 1;
     
        const { editmode, content, user } = this.props;
       
        var newsContent = this.fileterNewsContent(content);
      
        var timeStamp = Math.floor(Date.now() / 1000)
        return (
            <div>
            {user && user.isAdmin && editmode.isInEditMode &&

              <div className="text-right"> <a onClick={this.showNew} className="btn btn-secondary btn-add-news"><i className="fa fa-plus"></i> Add item</a></div>
              
            } 
         
           
             
                <ul className="newsWrapper">
                {content.showNew && <li><QuickCms name={"newstem"+timeStamp} pathName="homeNews" isNewItem={true}></QuickCms></li>}
                
                    {newsContent &&  newsContent.reverse().map(x => 

                        <li key={x.id}>
                            <QuickCms name={x.name} pathName="homeNews" isMultiple={true}></QuickCms>
                        </li>

                    )}
                  
                </ul>
            </div>  
            
        ) 
    }
}


function mapStateToProps(state) {
    const { authentication, editmode, content } = state;
    const { user } = authentication;
    return {
        user,
        editmode,
        content
    };
}

const connectedNews = connect(mapStateToProps)(News);
export { connectedNews as News };