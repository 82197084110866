
const urls = () =>{
   
    if( process.env.NODE_ENV=== 'qa') return qa;
    
    else if(process.env.NODE_ENV=== 'production') return prod;
 
    else{

        return dev;
    }
}


const dev ={
    apiUrl: 'http://localhost:5000',
    xlrUrl: 'http://staging.x-lr.com',
 
}
const qa ={
    apiUrl: 'http://10yearsapiqa.x-lr.com',
    xlrUrl: 'http://staging.x-lr.com',
  
}
const prod ={
  
    apiUrl: 'https://10yearsapi.x-lr.com',
    xlrUrl: 'https://portal.x-lr.com',
}



  export const config = {
  
    ssoUrl:'/login',
   ...urls()
     
 };