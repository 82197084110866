import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { contactActions } from '../../_actions';
import { QuickCms } from '../../_components/QuickCms';

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {
                name: this.props.user.firstName,
                email: '',
                message: '',
            },

            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { model } = this.state;
        this.setState({
            model: {
                ...model,
                [name]: value
            }
        });


    }
    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { model } = this.state;
        const { dispatch } = this.props;
        if (model.name && model.email && model.message) {
            if (this.validateEmail(model.email)) {
                dispatch(contactActions.sendEmail(model));
            }

        }
    }
    validateEmail(email) {
        if (email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
        else {
            return true
        }

    }
    render() {

        const { sending } = this.props;
        const { model, submitted } = this.state;
        return (
            <div className="row">
                <div className="container contact-us">
                    <div className="col-md-12">
                        <QuickCms name="ContactUsPage">
                            <h2>Contact Us</h2> 
                            <p>Please use the form below to contact us.</p>
                        </QuickCms>
                        <p>Boxes marked <sup><sup>*</sup></sup> are mandatory</p>


                        <div className="contact-form">

                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6 nopadding">
                                        <div className={'form-group' + (submitted && !model.name ? ' has-error' : '')}>
                                            <label htmlFor="name">Your Name <sup>*</sup></label>
                                            <input type="text" className="form-control" name="name" placeholder="Name" value={model.name} onChange={this.handleChange} />
                                            {submitted && !model.name &&
                                                <div className="help-block">Your name is required</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className={'form-group' + (submitted && !model.email ? ' has-error' : '')}>
                                            <label htmlFor="email">Your Email <sup>*</sup></label>
                                            <input type="text" className="form-control" name="email" placeholder="Email" value={model.email} onChange={this.handleChange} />
                                            {submitted && !model.email &&
                                                <div className="help-block">Your email is required</div>
                                            }
                                            {!this.validateEmail(model.email) &&
                                                <div className="help-block contact-email-block">Please enter a valid email address</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">

                                        <div className={'form-group' + (submitted && !model.message ? ' has-error' : '')}>
                                            <label htmlFor="message">Your Message <sup>*</sup></label>
                                            <textarea className="form-control message-field" name="message" placeholder="Enter your message" value={model.message} onChange={this.handleChange} />
                                            {submitted && !model.message &&
                                                <div className="help-block">Your message is required</div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-primary">Submit</button>
                                            {sending &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                            <Link to="/" className="btn btn-link">Cancel</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedContactUsPage = connect(mapStateToProps)(ContactUsPage);
export { connectedContactUsPage as ContactUsPage };