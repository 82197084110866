export const quickCmsConstants = {
   

    CONTENT_SAVE_REQUEST: 'CONTENT_SAVING_REQUEST',
    CONTENT_SAVE_SUCCESS: 'CONTENT_SAVING_SUCCESS',
    CONTENT_DELETE_SUCCESS: 'CONTENT_DELETE_SUCCESS',
    CONTENT_SAVE_FAILURE: 'CONTENT_SAVING_FAILURE',
    CONTENT_SHOW_NEW:'  CONTENT_SHOW_NEW',
    CONTENT_GET_REQUEST: 'CONTENT_GETTING_REQUEST',
    CONTENT_GET_SUCCESS: 'CONTENT_GETTIN_REQUEST',
    CONTENT_GET_FAILURE: 'CONTENT_GETTING_FAILURE',

    QUICKCMS_SET_EDITMODE_ON: 'QUICK_CMS_EDITMODE_ON',
    QUICKCMS_SET_EDITMODE_OFF: 'QUICK_CMS_EDITMODE_0FF',
  
};