import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { config } from '../../_helpers';
import XareltoLogoPng from '../../assets/xarelto_logo.png'
import { connect } from 'react-redux';
import { QuickCms } from '../QuickCms';

class Footer extends React.Component {

  render(){
    const {user} = this.props;
  return(

  <div id="footer">
  <div className="container">
    <div className="row">
      <div className="col-sm-9"><span className="small">Confidential, for internal use only. Do not distribute.</span></div>
      <div className="col-sm-3"><img className="right" src={XareltoLogoPng} /> </div>
    </div>
    <div className="row">
    <div className="col-sm-4">
      <p>
      Copyright © Bayer AG
      </p>
      </div>
      <div className="col-sm-8">
      {user &&
        <ul className="bottom-nav right">
          <li><Link to="/conditionsofuse">Conditions of Use</Link></li>
          <li><Link to="/privacystatement">Privacy Statement</Link></li>
          <li><Link to="/imprint">Imprint</Link></li>
          <li><a target="blunk" href="https://pharma.bayer.com/en/treatment-care/report-a-side-effect/">Report A Side Effect</a></li>
        </ul>
      }
        {!user &&
       <ul className="bottom-nav right"><li><a className="admin-login-link" href="/adminLogin"><i className="fa fa-sign-in"></i> login</a></li></ul>
       }
      </div>
      </div>
      <div className="row">
          <div className="col-sm-12">
           
            {user &&
              <QuickCms name="footerDate" pathName="global">
             <p> Last updated on 29/03/2018 </p>
            </QuickCms>
            }
            
          </div>
      </div>
  </div>
  </div>
  );
}

}

 function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
      user  
    };
}

const connectedFooter = connect(mapStateToProps)(Footer);
export { connectedFooter as Footer };
