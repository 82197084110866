export const noValidateList =[
    'gender',
    'profilePhoto',
    'profilePhotoBase64',
    'memorablePhoto',
    'memorablePhotoBase64',
    'video',
    'videoBase64',
    'description'
]

export const checkEntryisValid = (item,model) =>{
    if(model[item] ==  '') return true;
    
    var type = "";
   
    switch (item) {
        case 'profilePhoto':
           return  validateFileType(model[item], "image");
           break;
        case 'memorablePhoto':
            return  validateFileType(model[item], "image");
            break;

        case 'video': 
            return validateFileType(model[item], "video");
            break;
    }

    return true;

}

export const validateFileType = (filename, type) =>{
    var a = filename.split(".");
    if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
        return false;
    }

    var ext = a.pop().toLowerCase(); 
   
    switch (type) {
        case 'image':
            if(ext != 'jpg' && ext !='png' && ext != 'gif'){
               
                return false;
            }
            break;
            
        case 'video':

            if(ext != 'mov' && ext !='mp4' ){
            
                return false;
            }
            break;
    }

    return true;
}
export const  checkOptionalsAreValid = (model)=>{
    
  
    if(!model.profilePhoto && !model.gender) {
        console.log("photo / avatar is inValid");
        return  false;
    }
    if(!model.memorablePhoto && !model.video && !model.description){
        console.log("story is invalid");
        return false;
    }

    if(model.description.split(" ").length >=400){
        console.log("description is too long");
        return false;
    }

    return true;
}