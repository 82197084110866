import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { TimeLine } from '../../_components/TimeLine';
import { News } from '../../_components/News';
import { QuickCms } from '../../_components/QuickCms';
import oneBrandLogoPng from '../../assets/one-brand-logo.png';
import homeVideo from '../../assets/home_video.mp4';
import { history } from '../../_helpers';


class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { homePageLoaded: false }
    }
    componentDidMount() {

        this.listenVideoScroll();
        this.setState({ homePageLoaded: true });

    }

    componentWillUnmount() {
        $(document).unbind('scroll');
    }
    listenVideoScroll() {


        var videoElement = $(".home-video");
        var ha = (videoElement.offset().top + videoElement.height());

        if ($(document).scrollTop() > ha + 500) {
            videoElement.trigger('pause');


        }

        $(document).on("scroll", function () {

            if ($(document).scrollTop() > ha + 500) {
                videoElement.trigger('pause');
                // videoElement.css('bottom', '0');

            }

        });
    }


    render() {
        const { user,content } = this.props;
        const { homePageLoaded } = this.state;
        return (
            <div className="page-inner">
                <div className="home-welcome-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <QuickCms name="homebanner">
                                <h2>Welcome to the 'Xarelto' 10-Year Anniversary Microsite</h2>
                                </QuickCms>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img src={oneBrandLogoPng} width="230px" />
                            </div>
                        </div>
                        <div className="row mt-20">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <video width="100%" className="home-video" src={homeVideo} controls autoPlay="autoPlay" />
                            <QuickCms name="homeVideoText">
                               
                                <br /><br /><strong>10 years of protecting patients from the consequences of blood clots</strong><br /><br />From its first approval in 2008, for the prevention of VTE in patients undergoing elective hip or knee replacement, ‘Xarelto’ has become the most broadly indicated non-vitamin K antagonist oral anticoagulant (NOAC), and has been used by more than 39 million patients<sup>5</sup> worldwide. This is your opportunity to tell your part of this amazing story!
                                
                            </QuickCms>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    {content.items &&  <News />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-trip-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">

                            <QuickCms name="winner">
                                <h4>Win a trip to the Berlin One2Ten ‘Xarelto’ Anniversary <br /> Town hall meeting for you and your team</h4>
                                <p>
                                    For a chance to win, simply submit a story showing us celebration and success in your local market over the past <br />
                                    10 years, whatever that might be. This is your chance to be flown to the One2Ten – ‘Xarelto’ 10 Year Anniversary <br />
                                    Town Hall in Berlin!
                       </p>
                            </QuickCms>
                            <Link className="btn btn-secondary" to="/upload">Add Your Story</Link>


                        </div>
                    </div>
                </div>
            </div>
                {
            this.state.homePageLoaded &&
                <TimeLine />
        }
            </div >

        );
    }
}

function mapStateToProps(state) {
    const { authentication,content } = state;
    const { user } = authentication;
    return {
        user,
        content

    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };