export const uploadsConstants = {

    GETALL_UPLOADS_REQUEST: 'UPLOADS_GETTING_REQUEST',
    GETALL_UPLOADS_SUCCESS: 'UPLOADS_GETTING_SUCCESS',
    GETALL_UPLOADS_FAILURE: 'UPLOADS_GETTING_FAILURE',

    DELETE_UPLOAD_REQUEST: 'UPLOAD_DELETE_REQUEST',
    DELETE_UPLOAD_SUCCESS: 'UPLOAD_DELETE_SUCCESS',
    DELETE_UPLOAD_FAILURE: 'UPLOAD_DELETE_FAILURE', 

    APPROVE_UPLOAD_REQUEST: 'APPROVE_REQUEST',
    APPROVE_UPLOAD_SUCCESS: 'APPROVE_SUCCESS',
    APPROVE_UPLOAD_FAILURE: 'APPROVE_FAILURE',

    EDIT_UPLOAD_REQUEST: 'EDIT_UPLOAD_REQUEST',
    EDIT_UPLOAD_SUCCESS: 'EDIT_UPLOAD_SUCCESS',
    EDIT_UPLOAD_FAILURE: 'EDIT_UPLOAD_FAILURE',

    CLOSE_UPLOAD: 'CLOSE_UPLOAD',
    CHANGE_UPLOAD_MODEL: 'CHANGE_MODEL'
};
