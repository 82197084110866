
import 'babel-polyfill';
import 'core-js/es6/map';
import 'intl';
import 'intl/locale-data/jsonp/en.js';
import "isomorphic-fetch";

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { App } from './App';
import styles from './css/app.css'
import './favicons/favicons.js'

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);