import { timelineConstants } from '../_constants';
import { timelineService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const timelineActions = {
    getTimeline,
    timelineLoaded
    
};

function getTimeline() {
    return dispatch => {
        dispatch(request());
        timelineService.getTimeline()
            .then(
                (timeline) => {
                    dispatch(success(timeline));

                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            )
    };
    function request() { return { type: timelineConstants.GET_TIMELINE_REQUEST } }
    function success(timeline) { return { type: timelineConstants.GET_TIMELINE_SUCCESS, timeline } }
    function failure(error) { return { type: timelineConstants.GET_TIMELINE_FAILURE, error } }
}


function timelineLoaded() {
    return dispatch => {
      
        dispatch(success());
     
    };
    function success() { return { type: timelineConstants.TIMELINE_LOADED_SUCCESS } }

   
}