import React from "react"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { connect } from "react-redux"
import { config } from '../../_helpers';
import { quickCmsActions, alertActions } from '../../_actions';
import { history } from '../../_helpers';

class QuickCms extends React.Component {

    constructor(props) {
        super(props);
        const { children, name, content, editmode, pathName, isNewItem, isMultiple } = this.props;


        var path = pathName ? pathName : history.location.pathname;
        this.state = {
            deleteMode: false,
            isContentLoading: true,
            itemContent: { text: ReactDOMServer.renderToStaticMarkup(children), name: name, pageName: path },
            editedContent: { text: '', name: name, pageName: path },
            isEditing: isNewItem ? true : false,

        }
        this.onEdit = this.onEdit.bind(this);

        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleChange = this.handleChange.bind(this);


    }
    componentDidMount() {
        const { content } = this.props;


        this.setContent(content);


    }
    componentWillReceiveProps(nextProps) {
        const { content } = this.props;


        if (content !== nextProps.content) {

            this.setContent(nextProps.content);



        }
        if (nextProps.content) {

            this.setState({ isContentLoading: false });
        }
        if (nextProps.content.deleted) {

            this.setState({ deleteMode: false });
        }


    }
    setContent(content) {

        var itemContent = this.getitemTextFromPageContent(content);
        if (itemContent) {
            this.setState({ itemContent: itemContent });

        }

        this.setState({ isContentLoading: false });

    }

    getitemTextFromPageContent(contentArr) {
        const { name, pathName } = this.props;
        var path = pathName ? pathName : history.location.pathname;
        var items = contentArr.items
        for (var i in items) {

            var content = items[i];

            if (content.contentPage == path && content.name == name) {
                content.pageName = content.contentPage;
                return content;
            }
        }

    }
    onDelete(e) {
        this.setState({ deleteMode: true });
    }
    onDeleteConfirm(e) {
      
        const { dispatch } = this.props;
        const { itemContent } = this.state;
        dispatch(quickCmsActions.deleteContent(itemContent));
    }
    onDeleteCancel(e) {
        this.setState({ deleteMode: false });
    }
    onEdit(e) {
        e.preventDefault();

        this.setState({ isEditing: true });


    }

    handleChange(event) {
        var textarea = event.target;
        const { editedContent } = this.state;

        editedContent.text = textarea.value;
        this.setState({ editedContent: editedContent });
    }
    onCancel(e) {
        e.preventDefault();
        this.setState({ isEditing: false });
    }
    onSave(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        const { editedContent } = this.state;
        if (editedContent.text != '') {
            dispatch(alertActions.request('Processing..'));
            dispatch(quickCmsActions.saveContent(editedContent));
            this.setState({ itemContent: editedContent });
        }

        this.setState({ isEditing: false });
    }

    render() {
        const { isEditing, isContentLoading, itemContent, deleteMode } = this.state;
        const { user, children, content, editmode, isMultiple } = this.props;

        return (

            <div>
                {isEditing && user.isAdmin && editmode.isInEditMode &&
                    <div className="quick-cms-popup-wrapper">
                        <div className="quick-cms-popUp"><textarea className="quick-cms-textarea" onChange={this.handleChange} defaultValue={itemContent.text}></textarea>
                            <a href="#" onClick={this.onSave} className="btn btn-secondary">Save</a>
                            <a href="#" onClick={this.onCancel} className="btn small">Cancel</a>
                        </div>
                    </div>

                }
                {(!isEditing && !isContentLoading) &&
                    <div>{ReactHtmlParser(itemContent.text)}</div>
                }
                {user && user.isAdmin && editmode.isInEditMode && !isEditing && !deleteMode &&
                    <div>
                        <a data-edit={this.props.name} className="quick-cms-edit" href="#" onClick={this.onEdit}><i className="fa fa-pencil"></i></a>&nbsp;
                        {isMultiple && <a data-edit={this.props.name} className="quick-cms-edit"  onClick={() => this.onDelete()}><i className="fa fa-trash"></i></a>}

                    </div>
                }
                {deleteMode &&
                    <div style={{marginBottom:'10px'}}>
                            <a data-edit={this.props.name} className="quick-cms-edit"  onClick={() => this.onDeleteCancel()}><i className="fa fa-times-circle"></i> Cancel</a>
                            <a data-edit={this.props.name} className="quick-cms-delete" onClick={() => this.onDeleteConfirm()}><i className="fa fa-trash"></i> Confirm</a>&nbsp;
                   
                    </div>
                }




            </div>
        );
    }




}



const mapStateToProps = (state) => {
    const { authentication, content, editmode } = state;
    const { user } = authentication;

    return {
        user,
        content,
        editmode
    };
}

const connectedQuickCms = connect(mapStateToProps)(QuickCms);
export { connectedQuickCms as QuickCms };