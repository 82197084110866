import { quickCmsConstants } from '../_constants';

export function content(state = {}, action) {
  switch (action.type) {
    case quickCmsConstants.CONTENT_GET_REQUEST:
      return { sending: true };
    case quickCmsConstants.CONTENT_GET_SUCCESS:
      
      return { ...state, items: action.content };

    case quickCmsConstants.CONTENT_GET_FAILURE:
      return {};
    case quickCmsConstants.CONTENT_SAVE_REQUEST:
      return { sending: true };
    case quickCmsConstants.CONTENT_SAVE_SUCCESS:
      return {...state, showNew:false, deleted:true,items:updateItems(action.content,state)};
    
    
  case quickCmsConstants.CONTENT_DELETE_SUCCESS:
      return {...state, deleted:true,items:removeItem(action.content,state)};

    case quickCmsConstants.CONTENT_SAVE_FAILURE:
      return {...state};
      case quickCmsConstants.CONTENT_SHOW_NEW:
      return {...state, showNew:true,deleted:true};
    default:
      return state
  }
  function updateItems(savedItem,content){
    var itemExists = false;
    var items =content.items
    savedItem.contentPage = savedItem.pageName
    for( var i in items){
      var item = items[i];
        if( item.name == savedItem.name && item.contentPage == savedItem.contentPage){
          items[i] = savedItem
          itemExists = true;
         
        }
    }
  if(!itemExists){
    content.items.push(savedItem);
  }
   
    return content.items;
  }
  function removeItem(itemToRemove,content){
   
    var items =content.items
  
    for( var i in items){
      var item = items[i];
        if( item.name == itemToRemove.name && item.contentPage == itemToRemove.contentPage){
            items.splice(i,1);
            return items;
        }
    }
    return content.items;
  }

}