import { authHeaderjSON, config } from '../_helpers';
import { history } from '../_helpers';
export const quickCmsService = {
    saveContent,
    deleteContent,
    getAppContentsForPage,
    getAllAppContents
    
};

function saveContent(content) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(content),
       
    };
  
    return fetch(config.apiUrl + '/appcontent/addUpdateContent', requestOptions)
        .then(handleResponse, handleError);
       
}
function deleteContent(content) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(content),
       
    };
  
    return fetch(config.apiUrl + '/appcontent/deleteContent', requestOptions)
        .then(handleResponse, handleError);
       
}


function getAllAppContents(){
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON()
      
    };
  
    return fetch(config.apiUrl + '/appcontent/getAllAppContents', requestOptions)
        .then(handleResponse, handleError) .then(content => {
          
           
            return content;
            });;
       
}
function getAppContentsForPage(pagename) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(pagename),
    };
  
    return fetch(config.apiUrl + '/appcontent/getAppContentsForPage', requestOptions)
        .then(handleResponse, handleError) .then(content => {
          
           
            return content;
            });;
       
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // logout user if response is unauthorized
            if(response.status =="401"){
             reject("unauthorized");
            
            }
          
            response.text().then(text =>reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}