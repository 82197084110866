import React from 'react';
import { connect } from "react-redux"
import { config } from '../../_helpers';

import { quickCmsActions} from '../../_actions';

class QuickCmsEditMode extends React.Component {

    constructor(props) {
        super(props);

        const {editmode} = this.props;

        this.state = {

            isInEditMode: false
        }

        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {


    }

    handleChange(event){

        const { dispatch } = this.props;
        var isChecked = event.target.checked;
        this.setState({isInEditMode: isChecked});
        dispatch(quickCmsActions.setQuickCmsEditMode(isChecked));
        
    }

    render() {
        const {user} = this.props;
        return (
            <div>
            {user.isAdmin && 
            <label className="switch">
                <input type="checkbox" onChange={this.handleChange}/>
                <span className="slider round"></span>
            </label>
            }
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { users, authentication,editmode } = state;
    const { user } = authentication;
    return {
        user,
        editmode
    };
}

const connectedQuickCmsEditMode = connect(mapStateToProps)(QuickCmsEditMode);
export { connectedQuickCmsEditMode as QuickCmsEditMode };