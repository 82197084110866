import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { config } from '../../_helpers';
import { alertActions } from '../../_actions';
import { connect } from 'react-redux';


class Alert extends React.Component {

    constructor(props) {
        super(props);
        this.clearAlert = this.clearAlert.bind(this);
    }


    clearAlert() {
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
    }

    render() {
        const { user, alert } = this.props;
        return (
            <div>
                {alert.message &&
                    <div>
                        <div className="alert-wrapper"></div>


                        <div className={`alert ${alert.type}`}>
                            {alert.type != 'alert-request' &&
                                <div>
                                    <div className={`header ${alert.type}`}><a className="btn close" onClick={this.clearAlert}>X</a></div>
                                    <div className="wrapper">

                                        {alert.type == 'alert-success' &&
                                            <div>
                                                <i className=" icon fa fa-check-circle"></i>
                                                <h1> Thank you!</h1>
                                            </div>
                                        }
                                        {alert.type == 'alert-danger' &&
                                            <div>
                                               <i className="icon fa fa-exclamation-triangle"></i>
                                                <h1> Sorry!</h1>
                                            </div>}
                                        <p>{alert.message}</p>
                                        <a className="btn btn-secondary" onClick={this.clearAlert}>Close</a>
                                    </div>

                                </div>
                            }
                            {alert.type == 'alert-request' && <div><div className="loader"></div>{alert.message}</div>}

                        </div>
                    </div>

                }
            </div>

        );
    }

}

function mapStateToProps(state) {
    const { authentication, alert } = state;
    const { user } = authentication;
    return {
        alert,
        user
    };
}

const connectedAlert = connect(mapStateToProps)(Alert);
export { connectedAlert as Alert };
