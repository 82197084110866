export const countryData = [
    
    {
        "id": "AF",
        "name": "Afghanistan"
    },
    {
        "id": "AL",
        "name": "Albania"
    },
    {
        "id": "DZ",
        "name": "Algeria"
    },
    {
        "id": "AS",
        "name": "American Samoa"
    },
    {
        "id": "AD",
        "name": "Andorra"
    },
    {
        "id": "AO",
        "name": "Angola"
    },
    {
        "id": "AQ",
        "name": "Antarctica"
    },
    {
        "id": "AG",
        "name": "Antigua and Barbuda"
    },
    {
        "id": "AR",
        "name": "Argentina"
    },
    {
        "id": "AM",
        "name": "Armenia"
    },
    {
        "id": "AU",
        "name": "Australia"
    },
    {
        "id": "AT",
        "name": "Austria"
    },
    {
        "id": "AZ",
        "name": "Azerbaijan"
    },
    {
        "id": "BS",
        "name": "Bahamas"
    },
    {
        "id": "BH",
        "name": "Bahrain"
    },
    {
        "id": "BD",
        "name": "Bangladesh"
    },
    {
        "id": "BB",
        "name": "Barbados"
    },
    {
        "id": "BY",
        "name": "Belarus"
    },
    {
        "id": "BE",
        "name": "Belgium"
    },
    {
        "id": "BZ",
        "name": "Belize"
    },
    {
        "id": "BJ",
        "name": "Benin"
    },
    {
        "id": "BM",
        "name": "Bermuda"
    },
    {
        "id": "BT",
        "name": "Bhutan"
    },
    {
        "id": "BO",
        "name": "Bolivia"
    },
    {
        "id": "BA",
        "name": "Bosnia and Herzegovina"
    },
    {
        "id": "BW",
        "name": "Botswana"
    },
    {
        "id": "BV",
        "name": "Bouvet Island"
    },
    {
        "id": "BR",
        "name": "Brazil"
    },
    {
        "id": "IO",
        "name": "British Indian Ocean Territory"
    },
    {
        "id": "BN",
        "name": "Brunei Darussalam"
    },
    {
        "id": "BG",
        "name": "Bulgaria"
    },
    {
        "id": "BF",
        "name": "Burkina Faso"
    },
    {
        "id": "BI",
        "name": "Burundi"
    },
    {
        "id": "KH",
        "name": "Cambodia"
    },
    {
        "id": "CM",
        "name": "Cameroon"
    },
    {
        "id": "CA",
        "name": "Canada"
    },
    {
        "id": "CV",
        "name": "Cape Verde"
    },
    {
        "id": "CF",
        "name": "Central African Republic"
    },
    {
        "id": "TD",
        "name": "Chad"
    },
    {
        "id": "CL",
        "name": "Chile"
    },
    {
        "id": "CN",
        "name": "China"
    },
    {
        "id": "CO",
        "name": "Colombia"
    },
    {
        "id": "CR",
        "name": "Costa Rica"
    },
    {
        "id": "CI",
        "name": "C�te d'Ivoire"
    },
    {
        "id": "HR",
        "name": "Croatia"
    },
    {
        "id": "CU",
        "name": "Cuba"
    },
    {
        "id": "CW",
        "name": "Curacao"
    },
    {
        "id": "CY",
        "name": "Cyprus"
    },
    {
        "id": "CZ",
        "name": "Czech Republic"
    },
    {
        "id": "CD",
        "name": "Democratic Republic of Congo"
    },
    {
        "id": "DK",
        "name": "Denmark"
    },
    {
        "id": "DJ",
        "name": "Djibouti"
    },
    {
        "id": "DM",
        "name": "Dominica"
    },
    {
        "id": "DO",
        "name": "Dominican Republic"
    },
    {
        "id": "EC",
        "name": "Ecuador"
    },
    {
        "id": "EG",
        "name": "Egypt"
    },
    {
        "id": "SV",
        "name": "El salvador"
    },
    {
        "id": "GQ",
        "name": "Equatorial Guinea"
    },
    {
        "id": "EE",
        "name": "Estonia"
    },
    {
        "id": "ET",
        "name": "Ethiopia"
    },
    {
        "id": "FI",
        "name": "Finland"
    },
    {
        "id": "FR",
        "name": "France"
    },
    {
        "id": "PF",
        "name": "French Polynesia"
    },
    {
        "id": "GA",
        "name": "Gabon"
    },
    {
        "id": "GM",
        "name": "Gambia"
    },
    {
        "id": "GE",
        "name": "Georgia"
    },
    {
        "id": "DE",
        "name": "Germany"
    },
    {
        "id": "GH",
        "name": "Ghana"
    },
    {
        "id": "GR",
        "name": "Greece"
    },
    {
        "id": "GL",
        "name": "Greenland"
    },
    {
        "id": "GT",
        "name": "Guatemala"
    },
    {
        "id": "GN",
        "name": "Guinea"
    },
    {
        "id": "GW",
        "name": "Guinea Bissau"
    },
    {
        "id": "GY",
        "name": "Guyana"
    },
    {
        "id": "HN",
        "name": "Honduras"
    },
    {
        "id": "HK",
        "name": "Hong Kong"
    },
    {
        "id": "HU",
        "name": "Hungary"
    },
    {
        "id": "IS",
        "name": "Iceland"
    },
    {
        "id": "IN",
        "name": "India"
    },
    {
        "id": "ID",
        "name": "Indonesia"
    },
    {
        "id": "IR",
        "name": "Iran"
    },
    {
        "id": "IQ",
        "name": "Iraq"
    },
    {
        "id": "IE",
        "name": "Ireland"
    },
    {
        "id": "IL",
        "name": "Israel"
    },
    {
        "id": "IT",
        "name": "Italy"
    },
    {
        "id": "JM",
        "name": "Jamaica"
    },
    {
        "id": "JP",
        "name": "Japan"
    },
    {
        "id": "JO",
        "name": "Jordan"
    },
    {
        "id": "KZ",
        "name": "Kazakhstan"
    },
    {
        "id": "KE",
        "name": "Kenya"
    },
    {
        "id": "KW",
        "name": "Kuwait"
    },
    {
        "id": "KG",
        "name": "Kyrgyzstan"
    },
    {
        "id": "LV",
        "name": "Latvia"
    },
    {
        "id": "LB",
        "name": "Lebanon"
    },
    {
        "id": "LS",
        "name": "Lesotho"
    },
    {
        "id": "LR",
        "name": "Liberia"
    },
    {
        "id": "LY",
        "name": "Libyan Arab Jamahiriya"
    },
    {
        "id": "LT",
        "name": "Lithuania"
    },
    {
        "id": "LU",
        "name": "Luxembourg"
    },
    {
        "id": "MO",
        "name": "Macau"
    },
    {
        "id": "MK",
        "name": "Macedonia (FYR)"
    },
    {
        "id": "MG",
        "name": "Madagascar"
    },
    {
        "id": "MW",
        "name": "Malawi"
    },
    {
        "id": "MY",
        "name": "Malaysia"
    },
    {
        "id": "ML",
        "name": "Mali"
    },
    {
        "id": "MT",
        "name": "Malta"
    },
    {
        "id": "MQ",
        "name": "Martinique"
    },
    {
        "id": "MR",
        "name": "Mauritania"
    },
    {
        "id": "MU",
        "name": "Mauritius"
    },
    {
        "id": "MX",
        "name": "Mexico"
    },
    {
        "id": "FM",
        "name": "Micronesia"
    },
    {
        "id": "MD",
        "name": "Moldova"
    },
    {
        "id": "MC",
        "name": "Monaco"
    },
    {
        "id": "MN",
        "name": "Mongolia"
    },
    {
        "id": "MS",
        "name": "Montserrat"
    },
    {
        "id": "MA",
        "name": "Morocco"
    },
    {
        "id": "MZ",
        "name": "Mozambique"
    },
    {
        "id": "NA",
        "name": "Namibia"
    },
    {
        "id": "NL",
        "name": "Netherlands"
    },
    {
        "id": "AN",
        "name": "Netherlands Antilles"
    },
    {
        "id": "NC",
        "name": "New Caledonia"
    },
    {
        "id": "NZ",
        "name": "New Zealand"
    },
    {
        "id": "NI",
        "name": "Nicaragua"
    },
    {
        "id": "NE",
        "name": "Niger"
    },
    {
        "id": "NG",
        "name": "Nigeria"
    },
    {
        "id": "NO",
        "name": "Norway"
    },
    {
        "id": "OM",
        "name": "Oman"
    },
    {
        "id": "PK",
        "name": "Pakistan"
    },
    {
        "id": "PA",
        "name": "Panama"
    },
    {
        "id": "PY",
        "name": "Paraguay"
    },
    {
        "id": "PE",
        "name": "Peru"
    },
    {
        "id": "PH",
        "name": "Philippines"
    },
    {
        "id": "PL",
        "name": "Poland"
    },
    {
        "id": "PT",
        "name": "Portugal"
    },
    {
        "id": "PR",
        "name": "Puerto Rico"
    },
    {
        "id": "QA",
        "name": "Qatar"
    },
    {
        "id": "CG",
        "name": "Republic of the Congo"
    },
    {
        "id": "RE",
        "name": "Reunion"
    },
    {
        "id": "RO",
        "name": "Romania"
    },
    {
        "id": "RU",
        "name": "Russian Federation"
    },
    {
        "id": "RW",
        "name": "Rwanda"
    },
    {
        "id": "ST",
        "name": "Sao Tome and Principe"
    },
    {
        "id": "SA",
        "name": "Saudi Arabia"
    },
    {
        "id": "SN",
        "name": "Senegal"
    },
    {
        "id": "RS",
        "name": "Serbia"
    },
    {
        "id": "CS",
        "name": "Serbia and Montenegro"
    },
    {
        "id": "SC",
        "name": "Seychelles"
    },
    {
        "id": "SL",
        "name": "Sierra Leone"
    },
    {
        "id": "SG",
        "name": "Singapore"
    },
    {
        "id": "SK",
        "name": "Slovakia"
    },
    {
        "id": "SI",
        "name": "Slovenia"
    },
    {
        "id": "SO",
        "name": "Somalia"
    },
    {
        "id": "ZA",
        "name": "South Africa"
    },
    {
        "id": "GS",
        "name": "South Georgia"
    },
    {
        "id": "KR",
        "name": "South Korea"
    },
    {
        "id": "SS",
        "name": "South Sudan"
    },
    {
        "id": "ES",
        "name": "Spain"
    },
    {
        "id": "LK",
        "name": "Sri Lanka"
    },
    {
        "id": "SD",
        "name": "Sudan"
    },
    {
        "id": "SR",
        "name": "Suriname"
    },
    {
        "id": "SE",
        "name": "Sweden"
    },
    {
        "id": "CH",
        "name": "Switzerland"
    },
    {
        "id": "SY",
        "name": "Syria"
    },
    {
        "id": "TW",
        "name": "Taiwan"
    },
    {
        "id": "TJ",
        "name": "Tajikistan"
    },
    {
        "id": "TZ",
        "name": "Tanzania"
    },
    {
        "id": "TH",
        "name": "Thailand"
    },
    {
        "id": "TG",
        "name": "Togo"
    },
    {
        "id": "TT",
        "name": "Trinidad and Tobago"
    },
    {
        "id": "TN",
        "name": "Tunisia"
    },
    {
        "id": "TR",
        "name": "Turkey"
    },
    {
        "id": "TM",
        "name": "Turkmenistan"
    },
    {
        "id": "UG",
        "name": "Uganda"
    },
    {
        "id": "UA",
        "name": "Ukraine"
    },
    {
        "id": "AE",
        "name": "United Arab Emirates"
    },
    {
        "id": "GB",
        "name": "United Kingdom"
    },
    {
        "id": "US",
        "name": "United States of America"
    },
    {
        "id": "UY",
        "name": "Uruguay"
    },
    {
        "id": "UZ",
        "name": "Uzbekistan"
    },
    {
        "id": "VU",
        "name": "Vanuatu"
    },
    {
        "id": "VE",
        "name": "Venezuela"
    },
    {
        "id": "VN",
        "name": "Vietnam"
    },
    {
        "id": "VI",
        "name": "Virgin Islands (U.S.)"
    },
    {
        "id": "YE",
        "name": "Yemen"
    },
    {
        "id": "YU",
        "name": "Yugoslavia"
    },
    {
        "id": "ZM",
        "name": "Zambia"
    },
    {
        "id": "ZW",
        "name": "Zimbabwe"
    },
    {
        "id": "00",
        "name": "Not Applicable"
    }
];

export const regionData = [
    
    {
        "id": "1",
        "name": "Antarctica"
    },
    {
        "id": "2",
        "name": "Asia"
    },
    {
        "id": "3",
        "name": "Australia and Oceania"
    },
    {
        "id": "4",
        "name": "Central America and the Caribbean"
    },
    {
        "id": "5",
        "name": "Europe"
    },
    {
        "id": "6",
        "name": "Latin America"
    },
    {
        "id": "7",
        "name": "Middle East, North Africa, and Greater Arabia"
    },
    {
        "id": "8",
        "name": "North America"
    },
    {
        "id": "9",
        "name": "South Pacific Ocean"
    },
    {
        "id": "10",
        "name": "Sub-Saharan Africa"
    },
    {
        "id": "11",
        "name": "Global"
    },
    {
        "id": "12",
        "name": "Other"
    }
];