
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { config } from '../../_helpers';
import { connect } from 'react-redux';
import { timelineActions } from '../../_actions';
import { getTimelineHtml, getYearsHtml, getyearsHtml, bindPopUpClick } from './js/timeline-helper.js';
import animateStyle from './css/animate-min.css'
import timelineStyle from './css/timeline.css'
import otherStyle from './css/other.css'

 
class TimeLine extends React.Component {
    constructor(props) {
        super(props);
        var controller = new ScrollMagic.Controller();
        this.state = {
            controller:controller,
           // timelineHtml: null,
           // yearsHtml: null

        };
    }
    componentDidMount() {
        const { showCookieMessage } = this.props;

        if (showCookieMessage == "no") this.getTimelineData();


    }
    getTimelineData() {
        const { dispatch, timeline } = this.props;
        if (!timeline.item) { dispatch(timelineActions.getTimeline()) }
        else {
            setTimeout(() => { this.renderTimeLine(timeline.item) }, 300);
          
        }
    }

    componentWillReceiveProps(nextprop) {
        const { dispatch, timeline, showCookieMessage } = this.props;


        if (showCookieMessage != nextprop.showCookieMessage) {

            if (nextprop.showCookieMessage == "no") this.getTimelineData();
        }
        if (timeline.item != nextprop.timeline.item) {
            var serverData = nextprop.timeline.item;
            this.renderTimeLine(serverData);

        }
    }

    componentWillUnmount() {
        var controller = this.state.controller;
        controller.destroy(true);
        controller = null;
        this.setState({controller:controller});
        $(window).unbind('resize');
        $(document).unbind('scroll');
        $('#tag').off();
        $('.popup-close').off();
        $('.refs-link').off();
      
        $('#demo').html("");
        $('#years').html("");
       
    }
    renderTimeLine(serverData) {
        const $self = this;
        const { user } = this.props;


        var timelineHtml = getTimelineHtml({ 'data': serverData });
        var yearsHtml = getyearsHtml(timelineHtml);

        /*this.setState({
            timelineHtml: timelineHtml,
            yearsHtml: yearsHtml
        });*/

        $("#demo").html(timelineHtml);
        $("#years").append(yearsHtml);
       
        $(function() {

        doStuff($self);
        
        });
      
        function doStuff($self) {
          
            bindPopUpClick(user);
            AOS.init();
            $(window).on("resize", function () { AOS.refresh(); });
            $self.onScroll();
            $self.redirectAnchor();
            bindFireworks($self);
        }



        function bindFireworks() {
          const {controller} = $self.state;

            // CREATE AND DISTRIBUTE FIREWORKS START
            var timelineHeight = $(document).height();
            var sectionHeight = $(window).height();

            // get the width and height of the window
            var width = $('.fix-years-top').width();
            var height = $('.fix-years-top').height();
            var segmentNumber = timelineHeight / sectionHeight;
            var fireworkNumber = segmentNumber.toFixed(0);



            for (var i = 0; i < fireworkNumber; i++) {
                var triggerID = 'trigger' + i;
                var fireworkID = 'firework-animate' + i;
                var left = Math.floor(Math.random() * (width - 100));
                var position = i * sectionHeight;
                var top = position;
                $("<div class='firework-anim'><div id='" + triggerID + "' class='triggerClass'></div><div id='" + fireworkID + "' class='firework'></div></div>").appendTo(".fix-years-top").css({
                    left: left,
                    top: top
                });
            }
            // CREATE AND DISTRIBUTE FIREWORKS END
            $('.firework-anim').each(function () {
                var currentTrigger = $(this).find('.triggerClass').attr('id');
                var fireworkID = $(this).find('.firework').attr('id');

                var scene = new ScrollMagic.Scene({ triggerElement: "#" + currentTrigger, duration: 700 })
                    // animate color and top border in relation to scroll position
                    .setTween("#" + fireworkID, { backgroundSize: "100% 100%" }) // the tween durtion can be omitted and defaults to 1
                    .addTo(controller);
            });

            $(".addStory").on('click', function () {
                window.location = "/upload";
            });


        }
        

    }
    onScroll() {
        var yearsHeader = $('.years');
        var offset = yearsHeader.offset()
        if (yearsHeader.length) {

            $(document).on("scroll", function () {
                fixHeaderOnScroll(offset)
                var scrollPos = $(document).scrollTop() - 600;
                $('.years a').each(function () {
                    var currLink = $(this);
                    var refElement = $(currLink.attr("href"));
                    if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                        $('.years .years-year a').removeClass("active");
                        currLink.addClass("active");
                    }
                    else {
                        currLink.removeClass("active");
                    }
                });

            });
        }
        function fixHeaderOnScroll(offset) {
          
            var scroll_start = $(document).scrollTop();
            if (scroll_start > offset.top) {
                $('body').addClass('fix-years');
                $('.refs-link').css('position', 'fixed');
            } else {
                $('body').removeClass('fix-years');
                $('.refs-link').css('position', 'absolute');
            }
        }
    }
    redirectAnchor() {
        var anchor = location.hash;
        if (anchor) {

            var link = document.createElement('a');
            link.class = "year-year"
            link.href = anchor;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }




    }
    

    render() {
        const { timelineHtml, yearsHtml } = this.state;
        return (
            <div className="fix-years-top">
                <div className="timeline-heading">
                    TIMELINE
                </div>
                <div className="parallax-window">
                    <div id="demo">
                    <div className="timeline-loading">Loading Timeline, please wait...</div>
                      
                    </div>
                    <div id="years" className="years">

                        <div className="addStory"><span></span>Add Your Story</div>
                     
                    </div>
                    <div className="refs-link">References</div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    const { authentication, timeline } = state;
    const { user,showCookieMessage } = authentication;

    return {
        showCookieMessage,
        user,
        timeline,
        showCookieMessage
    };
}

const connectedTimeLine = connect(mapStateToProps)(TimeLine);
export { connectedTimeLine as TimeLine };
