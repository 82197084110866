
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from 'react-calendar';
import { videoActions, alertActions, uploadsActions } from '../../_actions';
import { QuickCms } from '../../_components/QuickCms';
import { countryData, regionData } from '../../_helpers/data.js';
import { getBase64 } from '../../_helpers/base64.js';

import {checkEntryisValid, validateFileType, checkOptionalsAreValid } from '../../_helpers/validation.js'

class UploadApproval extends React.Component {


    constructor(props) {
        super(props);
        const { user } = this.props;

        this.state = {
            model: {
                name: user.displayName,
                jobTitle: '',
                region: user.region,
                country: user.country,
                indication: '',
                approvalDate: '',
                approvalCountry: '',
                agreeTerms: '',
                agreeTransparent: '',

            },

            submitted: false,
            invalidFiles: []
        };
        this.clearItem = this.clearItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.bindDropdowns();
    }

    componentWillReceiveProps(nextprop) {

    }
    handleSubmit(event) {

        event.preventDefault();
        this.setState({ submitted: true });
        const { model } = this.state;
        const { dispatch } = this.props;

        if (this.doValidation(model)) {
            console.log("its all valid... submitting!");
            dispatch(alertActions.request('Processing..'));

            dispatch(uploadsActions.uploadApproval(model));
        }
    }
    doValidation(model){
        var isValid = true;
        const { invalidFiles } = this.state;
        for (var item in model) {

            if (model[item] == '') {
                console.log("something that is required is empty")
                isValid = false;
            }
            else if (!checkEntryisValid(item, model)) {
                console.log(item + " is invalid")
                if (invalidFiles.indexOf(item) == -1) invalidFiles.push(item);

                this.setState({ invalidFiles: invalidFiles });
                isValid = false;
            }
        }
        return isValid;
    }
    clearItem(event) {
        const { model } = this.state;
        event.preventDefault();
        var name = $(event.target).attr("data-target");

        this.updateModel(name, '');
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.updateModel(name, value);
    }
    handleDateChange(date) {
        var momentObj = moment(date);
        var datestr = momentObj.format('DD/MM/YYYY');
        //  var datestr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        var name = "approvalDate";
        const { model } = this.state;
        this.setState({
            model: {
                ...model,
                [name]: datestr
            }
        });

        $("#approvalDate").val(datestr);
        $(".calendar").toggle();


    }
    updateModel(name, value) {
        const { model } = this.state;
        this.setState(
            {
                model: {
                    ...model,
                    [name]: value
                }
            }, () => this.itemStateChanged(name)
        );


    }
    itemStateChanged(name) {
        const { model, invalidFiles, fileIsInvalid } = this.state;
       
        if (!checkEntryisValid(name, model)) {
            console.log(name + "is invalid")
            if (invalidFiles.indexOf(name) == -1) {
                invalidFiles.push(name);
                this.setState({ invalidFiles: invalidFiles });

            }
        }
        else {
            var index = invalidFiles.indexOf(name);
            if (index > -1) {
                invalidFiles.splice(index, 1);
            }
        }

    }
    showCalendar() {
        $(".calendar").toggle();

    }
    bindDropdowns() {
        const { model } = this.state;
        $.each(countryData, function (i, option) {
            $('#country').append($('<option/>').attr("value", option.id).text(option.name));

        });
        $.each(regionData, function (i, option) {
            $('#region').append($('<option/>').attr("value", option.name).text(option.name));
        });
        $('#country').val(model.country);
        $('#region').val(model.region);
    }

    render() {
        const { sending } = this.props;
        const { model, submitted, invalidFiles, fileIsInvalid } = this.state;
        return (
            <div>
                <div className="row">

                    <div className="col-md-12">
                        <QuickCms name="ApprovalDateVideoText">
                            <h2>Add an Approval Date</h2>

                        </QuickCms>
                    </div>


                </div>

                <div className="row">

                    <div className="col-md-12">
                        <div className="video-upload-container">

                            <p>Boxes marked <sup>*</sup> are mandatory</p>

                            <div className="upload-form">

                                <form name="form" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h2>Your Details</h2>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.name ? ' has-error' : '')}>
                                                <label htmlFor="name">Your Name <sup>*</sup></label>
                                                <input type="text" className="form-control" name="name" placeholder="Name" value={model.name} onChange={this.handleChange} />
                                                {submitted && !model.name &&
                                                    <div className="help-block">Your name is required</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.jobTitle ? ' has-error' : '')}>
                                                <label htmlFor="jobTitle">Your Function <sup>*</sup></label>
                                                <input type="text" className="form-control" name="jobTitle" placeholder="Your Function" value={model.jobTitle} onChange={this.handleChange} />
                                                {submitted && !model.jobTitle &&
                                                    <div className="help-block">Your function is required</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.region ? ' has-error' : '')}>
                                                <label className="cutom-drop-down-arrow" htmlFor="region">Your Region <sup>*</sup>
                                                    <select className="form-control" id="region" name="region" placeholder="Select Region" value={model.region} onChange={this.handleChange} ><option value="">Select Region</option></select>
                                                    {submitted && !model.region &&
                                                        <div className="help-block">Your region is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.country ? ' has-error' : '')}>
                                                <label className="cutom-drop-down-arrow" htmlFor="country">Your Country <sup>*</sup>
                                                    <select className="form-control" id="country" name="country" placeholder="Select Country" value={model.country} onChange={this.handleChange} ><option value="">Select Country</option></select>
                                                    {submitted && !model.country &&
                                                        <div className="help-block">Your country is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>


                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.indication ? ' has-error' : '')}>
                                            <label className="cutom-drop-down-arrow" htmlFor="indication">Indication <sup>*</sup>
                                                    <select className="form-control" id="indication" name="indication" placeholder="Select Indication" value={model.indication} onChange={this.handleChange} >
                                                        <option value="">Select Indication</option>
                                                        <option value="Prevention of thrombosis following elective hip/knee replacement">Prevention of thrombosis following elective hip/knee replacement</option>
                                                        <option value="Stroke prevention in non-valvular atrial fibrillation">Stroke prevention in non-valvular atrial fibrillation</option>
                                                        <option value="Treatment of DVT">Treatment of DVT</option>
                                                        <option value="Treatment of PE">Treatment of PE</option>
                                                        <option value="Secondary prevention of VTE">Secondary prevention of VTE</option>
                                                        <option value="Secondary prevention of ACS">Secondary prevention of ACS</option>
                                                    </select>
                                                    {submitted && !model.indication &&
                                                        <div className="help-block">Indication is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.approvalDate ? ' has-error' : '')}>
                                                <label className="custom-date-icon" htmlFor="approvalDate">Approval Date <sup>*</sup>
                                                    <span className="glyphicon glyphicon-calendar"></span>
                                                    <input type="text" className="form-control calendar-input" readOnly name="approvalDate" placeholder="Select a approval date" onClick={this.showCalendar} value={model.approvalDate} onChange={this.handleChange} />
                                                    <Calendar className="calendar" minDetail="year" view="month" onChange={this.handleDateChange} />

                                                    {submitted && !model.approvalDate &&
                                                        <div className="help-block">The approval date is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.approvalCountry ? ' has-error' : '')}>
                                                <label htmlFor="name">Country <sup>*</sup></label>
                                                <input type="text" className="form-control" name="approvalCountry" placeholder="Enter Country" value={model.approvalCountry} onChange={this.handleChange} />
                                                {submitted && !model.approvalCountry &&
                                                    <div className="help-block">Country is required</div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 custom-check-box">
                                            <div className={'form-group ' + (submitted && !model.agreeTerms ? ' has-error' : '')}>

                                                <input type="checkbox" name="agreeTerms" id="agreeTerms" value="true" onChange={this.handleChange} />
                                                <label htmlFor="agreeTerms">I confirm that I have read and agree to the <a href="/conditionsofuse" target="_blank">terms and conditions</a>.</label>
                                                {submitted && !model.agreeTerms &&
                                                    <div className="help-block">You must accept the terms and conditions</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-12 custom-check-box">
                                            <div className={'form-group' + (submitted && !model.agreeTransparent ? ' has-error' : '')}>

                                                <input type="checkbox" name="agreeTransparent" id="agreeTransparent" value="true" onChange={this.handleChange} />
                                                <label htmlFor="agreeTransparent">Data submitted in this form will be processed by an external agency for addition to the timeline on the 'Xarelto' One2Ten website. Information will be stored for the lifetime of the website, unless you request it to be removed. Stories may also be shared on various internal multimedia channels. Please tick to confirm that you understand and accept that your information will be used in this way.</label>
                                                {submitted && !model.agreeTransparent &&
                                                    <div className="help-block">You must confirm that you understand how the information provided will be used</div>
                                                }
                                            </div>


                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group submit-buttons">
                                                <button className="btn btn-primary">Submit</button>
                                                {sending &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                                <a href="/help" className="btn btn-link" target="_blank"><span>?</span><u>Need help?</u></a>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}



function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedUploadApproval = connect(mapStateToProps)(UploadApproval);
export { connectedUploadApproval as UploadApproval };