import { quizConstants } from '../_constants';
import { quizService } from '../_services';
import { alertActions } from '.';

export const quizActions = {
    saveQuizResults
};

function saveQuizResults(quizResults) {

    return dispatch => {
        quizService.saveQuizResults(quizResults)
        .then(
            (quizAggregates) => { 
                dispatch(success(quizAggregates));
            },
            error => {
                dispatch(failure(error));
            }
        )     
    };

    function success(quizAggregates) {
        
         return { type: quizConstants.RESULTS_SAVE_SUCCESS, quizAggregates }
     }
    function failure(error) {

        return { type: quizConstants.RESULTS_SAVE_FAILURE, error }
    }

}
