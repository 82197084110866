import React from 'react';
import { Link } from 'react-router-dom';
import ReactPiwik from 'react-piwik';
import { connect, } from 'react-redux';
import { downloadActions, alertActions } from '../../_actions';
import { QuickCms } from '../../_components/QuickCms';
import { getBlob } from '../../_helpers/base64.js'
import defaultThumbnail from '../../assets/default-thumbnail.png';
import story_of_xarelto from '../../assets/thumbs/story_of_xarelto.png'
import evolutionVideo from '../../assets/thumbs/evolutionVideo.jpg';
import externalZip from '../../assets/thumbs/externalLogo.jpg'
import externalLogoGuide from '../../assets/thumbs/externalLogoGuidance.jpg'
import { config } from '../../_helpers';

class DownloadExternalPage extends React.Component {

    componentDidMount() {
        this.bindDownloadLink();

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.file !== nextProps.file) {
            // this.downloadFile( nextProps.file);
         this.downloadFileByName( nextProps.file);
        }
    }
    bindDownloadLink() {
        var $this = this;
        $("[data-file]").click(function (e) {
            e.preventDefault();
            var fileName = $(this).attr("data-file");
           // $this.getFile(fileName);
         $this.getFileName(fileName);
        });

    }
    downloadFile(file) {
        var filename = file.filename
        var blob = file


        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);

        }
    


    }
  
    getFileName(fileName){
   
        const { dispatch } = this.props;
        const { file } = this.props;
        //dispatch(alertActions.request('Downloading..'));
        dispatch(downloadActions.getEncryptedFileName(fileName));
}

    getFile(fileName) {

        const { dispatch } = this.props;
        const { file } = this.props;
        dispatch(alertActions.request('Downloading..'));
        dispatch(downloadActions.getFile(fileName));
    }
    downloadFileByName(file){
       
            var elem = window.document.createElement('a');
            elem.href = config.apiUrl +"/download/getSecuredFile/?name="+file.name
            elem.download = file.filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
            this.submitAnalytics( file.filename);
         
       
      
      
    }
    submitAnalytics(filename){
        const { user } = this.props;
        var myevent = filename+" downloaded by user id:" + user.id;
    
        ReactPiwik.push(['trackEvent', 'File Download', myevent]);
    }
    render() {

        const { user } = this.props;
        const { file } = this.props;
        return (
            <div className="row">
                <div className="container download-toolkit-wrap">

                    <div className="col-md-12">
                        <QuickCms name="DownloadExternalText">
                            <h2>Download External Toolkit</h2>
                            <p>Coming soon</p>

                        </QuickCms>
                        <div>
                        <h3>You can download the following:</h3>
                        <div className="row">
                        
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Story of 'Xarelto'_Speaker Slides.pptx">
                                                <img src={story_of_xarelto} /><br />
                                                <p>
                                                Story of 'Xarelto' Speaker Slides <br />(ZIP, 8MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Xarelto_10Anniversary_Toolkit_ External_Brand_Elements.zip">
                                                <img src={externalZip} /><br />
                                                <p>
                                                 External Brand Elements <br />(ZIP, 9.12MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Xarelto_10Anniversary_Toolkit_External_Brand_Elements_Guidelines.pdf">
                                                <img src={externalLogoGuide} /><br />
                                                <p>
                                               External Brand Elements Guidelines  <br />(PDF, 1.27MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Xarelto_10Anniversary_Toolkit_Evolution.mp4">
                                                <img src={evolutionVideo} /><br />
                                                <p>
                                                10-Year Anniversary Film <br />(MP4, 70MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Xarelto_10Anniversary_Toolkit_Evolution_CAD_PAD.mp4">
                                                <img src={evolutionVideo} /><br />
                                                <p>
                                                10-Year Anniversary Film with CAD/PAD <br />(MP4, 30MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                     


                       
                    </div>
                </div>
            </div>
        )
    }


}

function mapStateToProps(state) {
    const { authentication, download } = state;
    const { user } = authentication;
    const { file } = download;
    return {
        user, file
    };
}

const connectedDownloadExternalPage = connect(mapStateToProps)(DownloadExternalPage);
export { connectedDownloadExternalPage as DownloadExternalPage };