import { userConstants } from '../_constants';

export function contact(state = {}, action) {
  switch (action.type) {
    case userConstants.CONTACT_REQUEST:
      return { sending: true };
    case userConstants.CONTACT_SUCCESS:
      return {};
    case userConstants.CONTACT_FAILURE:
      return {};
    default:
      return state
  }
}