import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import { config } from '../../_helpers';
import { userActions } from '../../_actions';
import cantReachThisPageImage from '../../assets/cant-reach-page.png';
import safariImage from '../../assets/safariImage.png'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());

        this.state = {
            username: '',
            password: '',
            xlrUrl: config.xlrUrl+"/account/Login.aspx?ReturnUrl=10years",
            hasQueryToken: false,
            submitted: false,
            showAdminForm: false,
            showCantLoginPopup: false

        };

        
        

            var tokenQuery = this.getParameterByName("token");

            if (tokenQuery != null) {
             
                this.state.hasQueryToken = true;
                const { dispatch } = this.props;
                dispatch(userActions.authenticateToken(tokenQuery));
            }
        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.bindShowCantLoginPopup = this.bindShowCantLoginPopup.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    componentDidMount(){
        var path = history.location.pathname;
       
        if (path.toLowerCase() == "/adminlogin") {
            this.setState({ showAdminForm: true });
        }
        var returnUrl = null;

        if(this.props.location && this.props.location.state){
            returnUrl = this.props.location.state.from;
        }
      
        if(returnUrl){
           // this.setState({ returnUrl: returnUrl });
           var hash ="";
           if(returnUrl.hash){
            hash = returnUrl.hash
           }

           localStorage.setItem('returnUrl', returnUrl.pathname + hash)
           console.log(returnUrl);
        }
    }
    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    bindShowCantLoginPopup(e){
        e.preventDefault();
      
        this.setState({showCantLoginPopup: true})

    }
    onClose(e) {
        e.preventDefault();
        this.setState({ showCantLoginPopup: false });
    }
    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted, showAdminForm,showCantLoginPopup,xlrUrl } = this.state;
        if (this.state.hasQueryToken) {
            return (<div></div>);
        }
        return (
            <div className="container log-in-page">
                {!showAdminForm &&
                    <div className="row">
                        <div className="col-md-6 col-xs-10 col-xs-offset-1 col-md-offset-3 ">
                            <div className="loginBox">
                                <h2>Bayer users</h2>
                                <p>
                                    You must be connected to the Bayer network. If you are not at a Bayer location, please ensure you are connected to the VPN.
                      </p>
                                <a className="btn btn-secondary" href="http://x-lr.bayer-ag.com/default.aspx?ReturnURL=10years">Login</a><br />
                                <a href="" onClick={this.bindShowCantLoginPopup}>Can't log in?</a>
                                <h2>Manual login</h2>
                                <p>
                                    If you have a username and password for X-LR, click <a href={xlrUrl} >here</a> to login .
              
                      </p>

                      {showCantLoginPopup &&
                <div className="cant-log-in-pop-up ">
               
                <div className="row">
                         <div className="col-md-12 col-xs-12">
                         <a className="close" href="" onClick={this.onClose}>x</a>
                            <div className="">
                                <h2>Can’t Login?</h2>
                                <p>
                                You must be connected to the Bayer network. If you are not at a Bayer <br/>
                                location, please ensure you are connected to the VPN.
                                </p>
                                <p>
                                If you get a page such as below, stating the site can’t be reached or can’t <br/>
                                find the server, please connect to the VPN and try to log in again.
                                </p><br/>
                                <img  src={cantReachThisPageImage} width="50%" className="left"/>
                                <img  src={safariImage} width="50%" className="left"/>
                                
                                <p>
                                If you are still unable to login after connecting to the VPN, please contact<br/>
                                Bayer IT for help
                               
                                </p>
                            </div>
                         </div>
                    </div>



                </div>
                }

                            </div>
                        </div>
                    </div>
                }
                {showAdminForm &&
                    <div className="row">
                        <div className="col-md-6 col-xs-10 col-xs-offset-1 col-md-offset-3">
                            <h2>Login</h2>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                                    {submitted && !username &&
                                        <div className="help-block">Username is required</div>
                                    }
                                </div>
                                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                    {submitted && !password &&
                                        <div className="help-block">Password is required</div>
                                    }
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary">Login
                                {loggingIn &&
                                            <div className="loader"></div>
                                        }
                                    </button>


                                </div>
                            </form>
                        </div>
                    </div>
                }
              
            </div>
            

        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 