import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QuickCms } from '../../_components/QuickCms';


class HelpPage extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    render() {
       
        const { user } = this.props;
        return (
            <div className="row">
                <div className="container help-page">
                    <div className="col-sm-12">
                    <br />
                        <Link to="#" onClick={this.goBack}>❮ back </Link>
                        <QuickCms name="helptext">
                            <h2>Help</h2>
                            <h2>Adding a Story</h2>
                            <p>
                                We want to view your stories of celebration and success in your local market over the past 10 years, whatever that might be. Add a story that will be shared on the microsite. You can add a story by using the <a href="/upload">form</a>.
            </p>
                            <p>
                                <p>
                                    A story can contain:
            </p>
                                <ul>
                                    <li>
                                        Text only,
               </li>
                                    <li>
                                        Memorable photo only,
               </li>
                                    <li>
                                        Memorable video only,
               </li>
                                    <li>
                                        A combination of the above.
               </li>
                                </ul>
                            </p>
                            <p>
                                Videos should be no longer than 1 minute.<br />
                                Images should be a good resolution and should not exceed 5MB.<br />
                                Text should be in English and no longer than 400 words.
            </p>
                        </QuickCms>
                        <QuickCms name="VideoGuidanceText">
                            <h2>Video Guidance</h2>
                            <p>
                                If submitting a video entry, we have provided some things to consider when creating your content, to ensure we gather high quality videos that can be shared not only on this microsite, but in other supporting videos to celebrate the 10 Year Anniversary.
            </p>
                            <ul>
                                <li>
                                    <strong>CONTENT:</strong> We want to hear your stories of celebration and success in your local market over the past 10 years, whatever that might be. Please keep the content in English so that everybody can understand it and gets motivated. Gather your teams to share these stories with colleagues around the world! Whatever your story, please start or end your video by saying “Happy Birthday ‘Xarelto’”, to enable us to create a Happy Birthday montage video with involvement from colleagues from around the world.
               </li>
                                <li>
                                    <strong>LENGTH:</strong> Videos should be no longer than one minute, so think carefully about what content you want to include.
               </li>
                                <li>
                                    <strong>QUALITY:</strong> Where possible, videos should be filmed landscape (not portrait) and should be filmed on an iPhone or equivalent, a full filming kit is not necessary.
               </li>
                                <li>
                                    <strong>SOUND:</strong> Make sure sound is enabled so we can clearly hear your message and ensure there is no distracting background noise that may prevent your story coming across.
               </li>
                                <li>
                                    <strong>LIGHTING:</strong> Ensure the video is well lit. Too dark and the subject will be hard to distinguish, too light and the quality of the image will be distorted.
               </li>
                                <li>
                                    <strong>DISTANCE:</strong> Make sure that you and your team are a reasonable distance away from the camera, if you are too far away we won’t be able to see you all clearly.
               </li>
                            </ul>
                        </QuickCms>
                        <QuickCms name="iPhoneTroubleshootingText">
                            <h2>
                                iPhone Troubleshooting
            </h2>
                            <h3>
                                Video Help
            </h3>
                            <h4>Length/Duration</h4>
                            <br />
                            <p>
                                If you’re getting an error on upload stating your file is too long in duration, you can use your phone’s edit function <br />(Photos > Select video > edit) to reduce the length.
            </p>
                            <h4> File Size</h4>
                            <br />
                            <p>
                                If you’re getting an error on upload stating your file is too large, you will need to compress the video and/or shorten it.
            </p>
                            <h3>Image Help</h3>
                            <h4> File Size</h4>
                            <br />
                            <p>
                                If you’re getting an error on upload stating your file is too large, you will need to compress the image.
            </p>
                            <p>
                                You can also reduce image file sizes by ensuring that HDR and Live Photos are turned off on your device.
            </p>
                            <h3>
                                Unable to Upload
            </h3>
                            <p>
                                If you are unable to upload your content, please ensure that your files are not too big. Also check that you have a good internet connection.
               If the problem persists, please try again later or <a href="/contact">contact us</a>
                            </p>
                        </QuickCms>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}
const connectedHelpPage = connect(mapStateToProps)(HelpPage);
export { connectedHelpPage as HelpPage };