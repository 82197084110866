import React from 'react';
import { connect } from 'react-redux';
import Question from './Question';
import Answer from './Answer';
import Result from './Result';
import "./styles.css";
import { tenYearsQuiz } from '../../_helpers/quizData'
import { QuickCms } from '../../_components/QuickCms';
import { quizActions } from '../../_actions';

class Quiz extends React.Component {
  constructor(props){
    super(props);
 
    let quiz = tenYearsQuiz;
    this.state = {
      step: 0,
      title: quiz.quizTitle,
      questions: quiz.questions,
      currentQuestion: quiz.questions[0],
      answers: [],
      correctAns: [],
      totalQuestions: quiz.questions.length,
      endQuiz: false,
      showQuizNavigationBtns: true,
      showResult: false,
      disableNextBtn: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleRetakeQuizBtn = this.handleRetakeQuizBtn.bind(this);
    this.handleGoNextButtonClick = this.handleGoNextButtonClick.bind(this);
    this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
    this.saveUserQuizResults = this.saveUserQuizResults.bind(this);

    this.parseCorrectAnswer();
  }
 
  parseCorrectAnswer(){
    const {correctAns, questions} = this.state;
    questions.map(question=>{
      return correctAns.push(question.correctAnswer);
    })
  }

  handleClick(index) {
      const { step, answers, questions } = this.state;
      if(!answers[step] || !questions[step].isMultipleChoiceQuestion){
        answers[step] = [];
      }
     
      if( answers[step].includes(String((index + 1)))){
        answers[step].splice(answers[step].indexOf(String((index + 1))), 1);
      }else{
        answers[step].push(String((index + 1 )));
      }
      this.setState({
        disableNextBtn: false
      });
   }
   handleRetakeQuizBtn(){
    let quiz = tenYearsQuiz;
     this.setState({
      step: 0,
      currentQuestion: quiz.questions[0],
      answers: [],
      endQuiz: false,
      showQuizNavigationBtns: true,
      showResult: false,
      disableNextBtn: false
     });
   }
   handleGoNextButtonClick(){
    const { dispatch } = this.props;
    const { step ,totalQuestions, questions, answers} = this.state;
   
    if(!answers[step] || !answers[step].length){
      this.setState({
        disableNextBtn: true
      });
      return;
    }
    let updatedStep = step;
	    if(step < totalQuestions - 1){
	       updatedStep = step + 1;
	        this.setState({
	        step: updatedStep,
          currentQuestion: questions[updatedStep],
          showQuizNavigationBtns: true
	      })
	    }else{
	      this.setState({
          endQuiz: true,
          showQuizNavigationBtns: false
        })
       this.saveUserQuizResults();
	    } 
   }
   saveUserQuizResults(){
    const { dispatch } = this.props;
    const { questions, answers} = this.state;

    var results =  questions.map(( item, index)=>{
        return {questionId: item.questionId, answers: answers[index]}
    });
    let model = {resultData: String(JSON.stringify(results))}
    console.log(model);
    dispatch(quizActions.saveQuizResults(model));
   }
   
   handlePreviousButtonClick(){
      const { step , questions} = this.state;
      let updatedStep = step;

      updatedStep = step - 1;
      this.setState({
        step: updatedStep,
        currentQuestion: questions[updatedStep],
        showQuizNavigationBtns: true,
        disableNextBtn: false,
      });
    }

  render() {
    const {title, questions, currentQuestion, answers, correctAns, endQuiz, step, totalQuestions,showQuizNavigationBtns,disableNextBtn} = this.state;
    return (
      <div className="react-quiz-container container ">
      <QuickCms name="quizTitleText">
        <h2>{title}</h2>
      </QuickCms>
        { endQuiz===true? (
          <Result questions={questions} answers={answers} correctAns={correctAns} handleRetakeQuiz={this.handleRetakeQuizBtn}/>
        ): (
          <div>
           <div className="row">
            <div className="col-lg-12">
             <Question currentQuestion={currentQuestion} qIdx={step}/>
             </div>
           </div>
           <div className="row">
              <div className="col-lg-12">
              <Answer questionType={currentQuestion.questionType} answers={currentQuestion.answers} handleClick={this.handleClick} renderInResult={false} userAnswers={answers} qIdx={step}/>
              </div>
            </div>
            {showQuizNavigationBtns &&
              <div className="row">
              <div className="col-lg-12 next_button_wrap">
                {step > 0 && <button className="btn-large prev" onClick={this.handlePreviousButtonClick}> Previous</button>}
                <button className="btn-large next" onClick={this.handleGoNextButtonClick} disabled={disableNextBtn}> {step < (totalQuestions -1) ?"Next":"Show results" } </button>
              </div>
            </div>
            }
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication
  return {
      user
  };
}

const connectedQuiz = connect(mapStateToProps)(Quiz);
export { connectedQuiz as Quiz };