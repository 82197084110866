import React, { Component } from "react";
import PropTypes from "prop-types";
import Question from "./Question";
import Answer from "./Answer";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: 0
    };
  }

  componentDidMount() {
    const { score } = this.state;
    const { correctAns, answers } = this.props;
    let tmpScore = score;
    answers.map((questionAnswers, index) => {
      if (this.subsetOf(correctAns[index], questionAnswers)) {
        tmpScore = tmpScore + 1;
      }
      return this.setState({
        score: tmpScore
      });
    });
  }
  subsetOf(firstArray, secondArray) {
    return secondArray.every(item => firstArray.includes(item));
  }
  render() {
    const { score } = this.state;
    const { questions, answers, correctAns } = this.props;
    const renderQuestion = questions.map((question, index) => {
      return (
        <div className="result-question" key={index}>
          <div className="row">
            <div className="col-lg-12">
              <Question currentQuestion={question} qIdx={index} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Answer
                questionType={question.questionType}
                answers={question.answers}
                userAnswers={answers}
                renderInResult={true}
                correctAns={correctAns}
                qIdx={index}
              />
            </div>
          </div>
        </div>
      );
    });
    const bottomRetakeQuizBtn = (
      <button
        className="btn-large next retake_quiz"
        onClick={() => this.props.handleRetakeQuiz()}
      >
        <i className="fa fa-refresh" aria-hidden="true" /> Retake quiz
      </button>
    );
    const renderResult = (
      <div>
        <div className="user-score row container">
          <h2 className="left">
            {" "}
            Your score:{" "}
            <span className="badge secondary">
              {score} / {questions.length}
            </span>
          </h2>
          <button
            className="btn-large next retake_quiz"
            onClick={() => this.props.handleRetakeQuiz()}
          >
            <i className="fa fa-refresh" aria-hidden="true" /> Retake quiz
          </button>
        </div>
      </div>
    );

    return (
      <div className="result-container">
        {renderResult}
        {renderQuestion}
        {bottomRetakeQuizBtn}
      </div>
    );
  }
}

Result.propTypes = {
  correctAns: PropTypes.array,
  answers: PropTypes.array,
  questions: PropTypes.array
};

export default Result;
