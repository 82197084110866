
export const tenYearsQuiz =  {
  "quizTitle": "‘xarelto’ 10-year anniversary quiz: 10 questions for 10 years",
  "questions": [
    {
      "questionNumber": "2008",
      "questionId": 1,
      "question": "In 2008,‘Xarelto’ was approved for the first time, for the prevention of VTE in adult patients undergoing elective hip or knee replacement surgery. But which country received first approval?",
      "questionType": "text",
      "answers": [
        "Germany",
        "Canada",
        "Brazil",
        "China",
		    "Ireland"
      ],
      "correctAnswer": ["2"],
      "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2009",
      "questionId": 2,
      "question": "When ‘Xarelto’ first launched, it had very distinctive branding. Do you remember what the original branding consisted of?",
      "questionType": "text",
      "answers": [
        "Clot changing into flowers",
        "Vein ‘highway’ with clot ‘traffic jam’",
        "Clot man",
        "Carabiner and knot",
		    "Rocket blasting into space filled with blood cell ‘stars’"
      ],
      "correctAnswer": ["3"],
      "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2010",
      "questionId": 3,
      "question": "Which of the following Phase 3 rivaroxaban trials completed in 2010?",
      "questionType": "text",
      "answers": [
        "ROCKET AF",
        "RECORD2",
        "COMPASS",
        "EINSTEIN DVT",
		"COMMANDER HF"
      ],
      "correctAnswer": ["1", "4"],
      "isMultipleChoiceQuestion" : true
    },
	  {
      "questionNumber": "2011",
      "questionId": 4,
      "question": "By December 2011, how many patients worldwide had been treated with ‘Xarelto’?",
      "questionType": "text",
      "answers": [
        "100,000",
        "250,000",
        "500,000",
        "1 million",
      "2 million"
        ],
        "correctAnswer": ["4"],
        "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2012",
      "questionId": 5,
      "question": "Which of the following studies were initiated in 2012?",
      "questionType": "text",
      "answers": [
        "XAMOS",
        "PIONEER AF-PCI",
        "X-VeRT",
        "EINSTEIN CHOICE",
		    "XALIA"
      ],
      "correctAnswer": ["3","5"],
      "isMultipleChoiceQuestion" : true
    },
	  {
      "questionNumber": "2013",
      "questionId": 6,
      "question": "We held our Annual Brand Meeting in Malta in 2013. Do you remember what its title was?",
      "questionType": "text",
      "answers": [
        "Strength2Strength",
        "Back2Basics",
        "First2Mind",
        "Approval2Uptake",
		    "Will2Win"
      ],
      "correctAnswer": ["5"],
      "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2014",
      "questionId": 7,
      "question": "‘Xarelto’ has one of the most extensive real-world evidence programmes of any NOAC. But which of the following is NOT a rivaroxaban RWE study?",
      "questionType": "text",
      "answers": [
        "EXPAND",
        "XALIA",
        "CRUNCH",
        "XANAP",
		    "RIVER"
      ],
      "correctAnswer": ["3"],
      "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2015",
      "questionId": 8,
      "question": "In January 2015, the European Commission approved a label update for ‘Xarelto’. What was this update?",
      "questionType": "text",
      "answers": [
        "For NVAF patients requiring PCI",
        "For NVAF patients undergoing cardioversion",
        "For NVAF patients requiring ablation",
        "For NVAF patients requiring TAVR",
		    "For NVAF patients requiring tonsillectomy"
      ],
      "correctAnswer": ["2"],
      "isMultipleChoiceQuestion" : false
    },
    {
      "questionNumber": "2016",
      "questionId": 9,
      "question": "In 2016, we had not one but two Annual Brand Meetings! For the second one, With1Voice in Vienna, we recorded our own ‘Xarelto’ version of a famous 80s power ballad. Can you remember what that song was?",
      "questionType": "text",
      "answers": [
        "The Power of Love, by Jennifer Rush",
        "Wind Beneath my Wings, by Bette Midler",
        "Take my Breath Away, by Berlin",
        "Simply the Best, by Tina Turner",
		    "Don’t Stop Thinking About Tomorrow, by Fleetwood Mac"
      ],
      "correctAnswer": ["4"],
      "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2017",
      "questionId": 10,
      "question": "We’ve come a long way since 2008. In December 2017, how many patients worldwide had been treated with ‘Xarelto’?",
      "questionType": "text",
      "answers": [
        "5 million",
        "18 million",
        "27 million",
        "30 million",
		    "39 million"
      ],
      "correctAnswer": ["5"],
      "isMultipleChoiceQuestion" : false
    },
	  {
      "questionNumber": "2018 (Bonus question)",
      "questionId": 11,
      "question": "It’s our 10-Year Anniversary. If this were a wedding anniversary, what anniversary would it be?",
      "questionType": "text",
      "answers": [
        "Wood anniversary",
        "Cotton anniversary",
        "Tin anniversary",
        "Leather anniversary",
		    "Lace anniversary"
      ],
      "correctAnswer": ["3"],
      "isMultipleChoiceQuestion" : false
    }
  ]
} 