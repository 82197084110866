import React from 'react';
import PropTypes from 'prop-types'
import { Router, Route,Redirect, BrowserRouter,browserHistory,Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';

import { userActions,alertActions,quickCmsActions } from '../_actions';
import { PrivateRoute } from '../_components/PrivateRoute';
import { AdminRoute } from '../_components/AdminRoute';
import { HomePage } from '../_pages/HomePage';
import { LoginPage } from '../_pages/LoginPage';
import { DownloadPage } from '../_pages/DownloadPage';
import { DownloadExternalPage } from '../_pages/DownloadExternalPage';
import { DownloadTownhallPage } from '../_pages/DownloadTownhallPage';
import {ContactUsPage} from '../_pages/ContactUsPage';
import {HelpPage} from '../_pages/HelpPage';
import { ConditionsOfUsePage } from '../_pages/ConditionsOfUsePage';
import { PrivacyStatementPage } from '../_pages/PrivacyStatementPage';
import { ImprintPage } from '../_pages/ImprintPage';
import {UsersPage} from '../_pages/UsersPage';
import {NotFoundPage} from '../_pages/NotFoundPage';

import {AllStoriesPage} from '../_pages/AllStoriesPage';
import { UploadPage } from '../_pages/UploadPage';
import { Header } from '../_components/Header';
import { Footer } from '../_components/Footer';
import { Piwik } from '../_components/Piwik';
import { CookieMessage } from '../_components/CookieMessage';
import { Alert } from '../_components/Alert';
import { QuizPage } from '../_pages/QuizPage';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timemout:null
        };
        
       // this.resetLogout();
        this.getContent();
      this.checkCookieMessage();
        
       
      
        const { dispatch,isInEditMode } = this.props;
        history.listen((location, action) => {
            
            dispatch(alertActions.clear());
         
           // this.resetLogout();
            
            if(action == "PUSH"){ 
                window.scrollTo(0,0); 
                this.checkCookieMessage();
                   this.getContent();
                   this.setQuickCmsEditMode();
               }
         
        });
    //remove this
      //  dispatch(alertActions.success("Thank you! Your story will be processed and will appear on the timeline soon."));
      
    }
    checkCookieMessage() {


        if (this.props.showCookieMessage != 'no') {
            //check that its not already saved.
        

            if (localStorage.getItem('showCookieMessage') == "no") {
               this.props.dispatch(userActions.acceptCookiMessage());
            }
        }


    }

 
    resetLogout(){
 
    clearTimeout(this.state.timemout );
    //set 40 minute idle
    this.state.timemout = setTimeout(function() { history.push('/login'); }, 1000 * 60 * 40);
  


  }
  

   getContent(){
       var path = history.location.pathname;
    if(path != '/login' && path != '/adminlogin'){
        const { dispatch,content } = this.props;
        if(!content[path]){
            dispatch(quickCmsActions.getAppContentsForPage(path));
        }
       
    }
  
   }
  
   setQuickCmsEditMode(){
    const { dispatch,editmode } = this.props;
    dispatch(quickCmsActions.setQuickCmsEditMode(editmode.isInEditMode));
   }

    render() {
        const { router, params, location, routes,editmode,user,alert } = this.props
      

        return (
        
            <div className="wrapper">
            <Piwik></Piwik>
            
            <Router history={history}>
                 <Header location={history.location.pathname} />
            </Router>
            {user && <CookieMessage  />}
               
                        <Alert />
                        <Router history={history}> 
                            <div className="page-wrap">
                            <Switch>
                                <Route path='/bayerSSo' component={() => window.location = 'https://x-lr.bayer-ag.com?redirectUrl="10years'}/>
                                <PrivateRoute exact path="/" component={HomePage} />
                                <Route path="/login" component={LoginPage} />
                                <Route path="/adminlogin" component={LoginPage} />
                              
                                <PrivateRoute path="/downloads" component={DownloadPage} />
                                <PrivateRoute path="/external-downloads" component={DownloadExternalPage} />
                                <PrivateRoute path="/townhall-downloads" component={DownloadTownhallPage} />
                                <PrivateRoute path="/upload" component={UploadPage} />
                                <PrivateRoute path="/quiz" component={QuizPage} />
                                <PrivateRoute path="/contact" component={ContactUsPage} />
                                <PrivateRoute path="/help" component={HelpPage} />
                                <PrivateRoute path="/conditionsofuse" component={ConditionsOfUsePage} />
                                <PrivateRoute path="/users" component={UsersPage} />
                                <PrivateRoute path="/privacystatement" component={PrivacyStatementPage} />
                                <PrivateRoute path="/imprint" component={ImprintPage} />
                                <AdminRoute path="/allstories" component={AllStoriesPage} user={user} />
                                <PrivateRoute path='*' exact={true} component={NotFoundPage} />
                                </Switch>
                            </div>
                        </Router>
               
                <Router history={history}>
                    <Footer/>
                </Router>
                {this.props.children}
            </div>
           
        );
    }
}
App.contextTypes = {
    router: PropTypes.object,
  }
function mapStateToProps(state) {
    const { alert,authentication, editmode,content } = state;
    const { user,showCookieMessage } = authentication;
    return {
        alert,
        user,
        editmode,
        content,
        showCookieMessage
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 