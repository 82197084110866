import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { loggedIn: true, user,showCookieMessage:'yes' } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        showCookieMessage:'no',
        loggingIn: true,
        user: action.user
      };
      
    case userConstants.LOGIN_SUCCESS:
      return {
        showCookieMessage:'yes',
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};

      case userConstants.COOKIE_MESSAGE_ACCEPT:
        return {
        ...state,
        showCookieMessage: 'no'
        };
      
    default:
      return state
  }
}