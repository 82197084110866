import { userConstants } from '../_constants';
import { videoService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const videoActions = {
    uploadVideo,
    changeFormType,
    uploadBirthdayVideo
};

function uploadVideo(video) {
   
    return dispatch => {
       
        videoService.uploadVideo(video)

        .then(
            () => { 
                dispatch(success(video));
                history.push('/');
                dispatch(alertActions.success('Your story will be processed and will appear on the timeline soon.'));
               
            },
            error => {
                console.log(error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
     
    };
  
    function success(video) { return { type: userConstants.VIDEO_SUCCESS, video } }
    function failure(error) { return { type: userConstants.VIDEO_FAILURE, error } }

   
}
function uploadBirthdayVideo(video) {
   
    return dispatch => {
       
        videoService.uploadBirthdayVideo(video)
        .then(
            () => { 
                dispatch(success(video));
                history.push('/');
                dispatch(alertActions.success('Your video has been uploaded and will be processed. '));
            },
            error => {
                console.log(error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
  
    function success(video) { return { type: userConstants.VIDEO_SUCCESS, video } }
    function failure(error) { return { type: userConstants.VIDEO_FAILURE, error } }
}
function changeFormType(formType) {
   
    return dispatch => {
       
        dispatch(success(formType));
     
    };
    function success(formType) { return { type: userConstants.VIDEO_CHOICE_CHANGE,formType } }

   
}