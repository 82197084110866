import { quickCmsConstants } from '../_constants';
import { quickCmsService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const quickCmsActions = {
    getAppContentsForPage,
    saveContent,
    deleteContent,
    setQuickCmsEditMode,
    showNew
    
};

function getAppContentsForPage(pagename){
    return dispatch => {
        quickCmsService.getAllAppContents()
        .then(
            (content) => { 
                dispatch(success(content));
               
            },
            error => {
                console.log(error);
                if(error ="unauthorized"){
                    history.push('/login');
                    return;
                }
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        )     
    };
    function success(content) { return { type: quickCmsConstants.CONTENT_GET_SUCCESS, content } }
    function failure(error) { return { type: quickCmsConstants.CONTENT_GET_FAILURE, error } }
}

function setQuickCmsEditMode(isInEditMode){

    return dispatch => {

        if(isInEditMode){
            dispatch(success(isInEditMode));
        }else{
            dispatch(failure(isInEditMode));
        }
    };
    function success(isInEditMode) { return { type: quickCmsConstants.QUICKCMS_SET_EDITMODE_ON, isInEditMode } }
    function failure(isInEditMode) { return { type: quickCmsConstants.QUICKCMS_SET_EDITMODE_OFF, isInEditMode } }
}



function saveContent(content) {

    return dispatch => {
        quickCmsService.saveContent(content)
        .then(
            (item) => { 
                content.id = item.id;
                dispatch(success(content));
                dispatch(alertActions.success('Content saved :)'));
               
            },
            error => {
                console.log(error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        )     
    };

    function success(content) { return { type: quickCmsConstants.CONTENT_SAVE_SUCCESS, content } }
    function failure(error) { return { type: quickCmsConstants.CONTENT_SAVE_FAILURE, error } }

}
function deleteContent(content) {

    return dispatch => {
        quickCmsService.deleteContent(content)
        .then(
            () => { 
                dispatch(success(content));
                dispatch(alertActions.success('Content deleted :)'));
               
            },
            error => {
                console.log(error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        )     
    };

    function success(content) { return { type: quickCmsConstants.CONTENT_DELETE_SUCCESS, content } }
    function failure(error) { return { type: quickCmsConstants.CONTENT_SAVE_FAILURE, error } }

}
function showNew() {
    return dispatch => {
      
        dispatch(success());
     
    };
    function success() { return { type: quickCmsConstants.CONTENT_SHOW_NEW } }

   
}