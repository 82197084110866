import ReactPiwik from 'react-piwik';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', 'img/timeline/')] = r(item); });
    return images;
}

var images = importAll(require.context('../img/timeline', false, /\.(png|jpe?g|svg)$/));


export const getTimelineHtml = function (options) {


    var obj_data = options.data; /*Main data*/

    function sortNumber(a, b) { /*Sort Number*/
        return a - b;
    }

    var month_name = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    var dates = [];
    for (var slide in obj_data) {
        var d = new Date(obj_data[slide].time);
        var t = d.getTime();
        dates.push(t);
    }

    dates.sort(sortNumber);
    var tl_data = {};
    for (var c_date in dates) {
        var d = new Date(dates[c_date]);
        var y = d.getFullYear();
        var m = d.getMonth();
        m = m + 1;
        var dt = d.getDate();
        if (typeof tl_data[y] != 'object') {
            tl_data[y] = {};
        }
        if (typeof tl_data[y][m] != 'object') {
            tl_data[y][m] = {};
        }
        if (typeof tl_data[y][m][dt] != 'object') {
            tl_data[y][m][dt] = [];
        }
    }

    for (var slide in obj_data) {
        var d = new Date(obj_data[slide].time);
        var y = d.getFullYear();
        var m = d.getMonth();
        m = m + 1;
        var dt = d.getDate();
        tl_data[y][m][dt].push(obj_data[slide].tags);
    }

    var tl_html_arr = [];
    for (var get_year in tl_data) {
        var html_str = '';
        html_str += '<div id="year' + get_year + '" class="year y' + get_year + ' clearfix"><a id="year' + get_year + '" class="anchor">Anchor</a>';
        html_str += '<span class="ylabel">' + get_year + '</span>';
        var month_keys = Object.keys(tl_data[get_year]);
        //month_keys.reverse();
        month_keys;
        for (var i = 0; i < month_keys.length; i++) {
            var get_month = month_keys[i];
            var day_keys = Object.keys(tl_data[get_year][get_month]);
            day_keys.reverse();
            for (var j = 0; j < day_keys.length; j++) {
                var get_day = day_keys[j];
                for (var get_data in tl_data[get_year][get_month][get_day]) {
                    var dateStr = get_day + " " + month_name[get_month - 1] + " " + get_year;
                    html_str += '<div class="day d' + get_day + ' clearfix">';
                    html_str += '<span class="line"></span><div class="tag clearfix" data-aos-once="true">';
                    html_str += '<span class="dlabel" data-date="' + dateStr + '"><span class="date-number">' + get_day + '</span> <span class="date-month">' + month_name[get_month - 1] + '</span> ' + get_year + '</span>';

                    for (var gettag in tl_data[get_year][get_month][get_day][get_data]) {

                        html_str += '<' + tl_data[get_year][get_month][get_day][get_data][gettag].name;

                        if (tl_data[get_year][get_month][get_day][get_data][gettag].attr != undefined) {
                            for (var atr in tl_data[get_year][get_month][get_day][get_data][gettag].attr) {

                                var imageSrc = tl_data[get_year][get_month][get_day][get_data][gettag].attr[atr]

                                var localImage = images[imageSrc];
                                html_str += ' ' + atr;
                                html_str += '="';
                                html_str += localImage ? localImage : tl_data[get_year][get_month][get_day][get_data][gettag].attr[atr];
                                html_str += '"';

                            }
                        }


                        if (tl_data[get_year][get_month][get_day][get_data][gettag].name != 'img') {
                            html_str += '>';
                        }
                        if (tl_data[get_year][get_month][get_day][get_data][gettag].name == 'img') {
                            html_str += ' />';
                        }
                        if (tl_data[get_year][get_month][get_day][get_data][gettag].content) {
                            html_str += tl_data[get_year][get_month][get_day][get_data][gettag].content;
                        }

                        if (tl_data[get_year][get_month][get_day][get_data][gettag].name == 'video') {
                            html_str += '</video>';
                        }

                        else {
                            html_str += '</' + tl_data[get_year][get_month][get_day][get_data][gettag].name + '>';
                        }

                    }

                    html_str += '<div class="popup-open"><span>...</span></div>';
                    html_str += '</div>';
                    html_str += '</div>';


                }
            }
        }
        html_str += '</div>';
        tl_html_arr[get_year] = html_str;
    }

    var tl_html = '';
    tl_html_arr;

    for (i in tl_html_arr) {
        tl_html += tl_html_arr[i];
    }

    var html = '<div id="holder"><div class="midline"></div>' + tl_html + '</div>';
    var $html = $(html);


    $html.find('type').each(function () {
        var postType = $(this).html();
        $(this).closest('.day').addClass(postType);
    });

    /* custom stuff */


    $html.find(".day.event-logos .dlabel .date-month, .day.event .dlabel .date-month, .day.extended .dlabel .date-month").each(function () {
        var str = $(this).html();
        $(this).html(str.substr(0, 3))
    });

    AddAosDataSets($html);
    updateStoryitem($html);
    return $html.prop("outerHTML");




};


var AddAosDataSets = function ($html) {
    //aos 'show' animation on scroll if not IE
    if (!(/MSIE (\d+\.\d+);/.test(navigator.userAgent)) || navigator.userAgent.indexOf("Trident/") != -1) {


        $html.find('.day:nth-child(odd) .tag').attr('data-aos', 'fade-right');
        $html.find('.day:nth-child(even) .tag').attr('data-aos', 'fade-left');
        $html.find('.day.featured .tag').attr('data-aos', 'flip-left');
        $html.find('.day.extended .tag').attr('data-aos', 'zoom-out');
    }
}
var updateStoryitem = function ($html) {
    $html.find('.tag').each(function () {

        var previousElement = $(this).prev();
        var thumbImg = $(this).find('img.thumbnail');
        var gender = $(this).find('gender').html();
        previousElement.addClass(gender);
        previousElement.append(thumbImg);





    });
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1) {
        $html.find(".year .day .line").each(function () {
            var $container = $(this),
                imgUrl = $container.find("img").prop("src");

            if (imgUrl) {
                $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("custom-object-fit");
                $container.find("img").hide();
            }
        });
    }

}



export const getyearsHtml = function (html) {
    var $html = $(html);
    var yearsHtmlStr = ""
    $html.find('.ylabel').each(function () {
        var yearMenu = $(this).html();
        yearsHtmlStr += ('<div class="years-year"><a href="#year' + yearMenu + '">' + yearMenu + '</a></div>');
    });
    return yearsHtmlStr;
}


export const bindPopUpClick = function (user) {

    $('.tag').on('click', function (e) {
        if ($(this).parent().hasClass('event') || $(this).parent().hasClass('event-logos') || $(this).parent().hasClass('extended')) {
            return;
        }



        var popupContent = $(this).html();
        var popupGenderImg = $(this).prev().clone();
        renderPopUp();
        submitAnalytics(popupContent, user);


        function renderPopUp() {

            var $html = $(popupContent);
            $('.popup').addClass('open');
            $('.popup-content').prepend(popupGenderImg);
            $('.popup-content').append($html);
            $('.popup-content').find('.icon-img').each(function () {

                var image = $('<img />', {

                    src: $(this).attr('data-img'),

                });
                image.appendTo($(this));

            });

            $('.popup-content').find('.icon-video').each(function () {

                var video = $('<video />', {
                    id: 'video',
                    src: $(this).attr('data-video'),
                    type: 'video/mp4',
                    width: '558',
                    controls: true
                });
                video.appendTo($(this));
            });


            $('body').css('overflow', 'hidden');
            //log this to piwik
        }
        function submitAnalytics(content, user) {
            if (!content) return;
            var $html = $(content);

            if (!$html) return;


            var dateLabelElement = "";
            var displayNameElement = "";


            $.each($html, function (i, el) {


                if (el.className == "dlabel") dateLabelElement = el;
                if (el.nodeName == "H1") displayNameElement = el;

            });
            var username = $(displayNameElement).text();
            var storyDate = $(dateLabelElement).attr("data-date");

            var myevent = "Story by: " + username + " with date: " + storyDate + " opened by userid: " + user.id;

            ReactPiwik.push(['trackEvent', 'Popup Opened', myevent]);
        }

    });

    $('.popup-close').on('click', function (e) {

        $('.popup').removeClass('open');
        $('.popup-content').empty();
        $('body').css('overflow', 'auto');
    });
    $('.refs-link').on('click', function (e) {

        $('.popup').addClass('open');
        var popupContent = $("<h2>References</h2><p>1. The EINSTEIN Investigators. N Engl J Med. 2010;363:2499–2510.<br />2. Patel MR, et al. N Engl J Med. 2011;365:883–891.<br />3. The EINSTEIN–PE Investigators. N Engl J Med. 2012;366:1287–1297.<br />4. Mega JL, et al. N Engl J Med. 2012;366:9–19.<br />5. Bayer estimate based on calculations on IQVIA data (IQVIA MIDAS, Database Quarterly Sales).<br />6. Turpie AG, et al. Thromb Haemost. 2014;111:94–102.<br />7. Cappato R, et al. Eur Heart J. 2014;35:3346–3355.<br />8. Cappato R, et al. Eur Heart J. 2015;36:1805–1811.<br />9. Ageno W, et al. Lancet Haematol. 2016;3:e12–e21.<br />10. Gibson CM, et al. N Engl J Med. 2016;375:2423–2434.<br />11. Weitz JI, et al. N Engl J Med. 2017;376:1211–1222.<br />12. Eikelboom JW, et al. N Engl J Med. 2017;377:1319–1330.<br />&nbsp;");
        $('.popup-content').append(popupContent);
    });




}


