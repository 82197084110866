import React from 'react'
import { connect } from 'react-redux';
import { QuickCms } from '../../_components/QuickCms';
import { Link } from 'react-router-dom';

class ImprintPage extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }


    render() {
        const { user } = this.props;
        return (
            <div className="row">
                <div className="container footer-page">
                    <div className="col-sm-12">
                        <br />
                        <Link to="#" onClick={this.goBack}>❮ back </Link>
                        <QuickCms name="ImprintSupplierText">
                            <h2>Imprint</h2>
                            <h2>Supplier:</h2>
                            <p>
                                Bayer AG, Pharmaceuticals, Berlin, Germany
         </p>
                            <p>
                                Represented by the Management Board:<br />
                                Werner Baumann, Chairman Liam Condon, Johannes Dietsch, Hartmut Klusik, Kemal Malik, <br />
                                Erica Mann, Dieter Weinand
         </p>
                            <p>
                                Müllerstrasse 178<br />
                                Germany-13353 Berlin<br />
                                phone: +49-30-468-11 11<br />
                                for E-mail contact please click <a>here</a>.
         </p>
                            <p>Entry in the Commercial Register of the Cologne District Court (Amtsgericht): HRB 48248<br />
                                Turnover tax ID no.: DE 123659859
         </p>
                        </QuickCms>
                        <QuickCms name="ImprintEditedBy">
                            <h2>
                                Edited by:
         </h2>
                            <p>
                                Bayer AG<br />
                                Strategic Marketing, Pharmaceuticals<br />
                                13353 Berlin<br />
                                Germany
         </p>
                        </QuickCms>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}
const connectedImprintPage = connect(mapStateToProps)(ImprintPage);
export { connectedImprintPage as ImprintPage };