import { userConstants } from '../_constants';
import { contactService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const contactActions = {
    sendEmail,
};

function sendEmail(contact) {
    return dispatch => {
        contactService.sendEmail(contact)
        .then(
            () => { 
                dispatch(success(contact));
                history.push('/');
                dispatch(alertActions.success('Thank you for contacting us. Your message has been sent'));
               
            },
            error => {
                console.log(error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
     
    };
    function success(contact) { return { type: userConstants.CONTACT_SUCCESS, contact } }
    function failure(error) { return { type: userConstants.CONTACT_FAILURE, error } }
   
}