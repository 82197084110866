import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { AllStories } from '../../_components/AllStories/AllStories';


class AllStoriesPage extends React.Component {

    componentDidMount() {

    }

    render() {
        const { uploads } = this.props;
        return (
            <div className="container">
            <AllStories />   

            </div>

        );
    }
}

function mapStateToProps(state) {
    const { uploads, authentication } = state;

    return {

        uploads
    };
}

const connectedAllStoriesPage = connect(mapStateToProps)(AllStoriesPage );
export { connectedAllStoriesPage  as AllStoriesPage  };