import { authHeaderjSON, config } from '../_helpers';

export const videoService = {
  uploadVideo,
  uploadBirthdayVideo
};

function uploadVideo(video) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(video), 
     
    };

    return fetch(config.apiUrl + '/upload/uploadStory', requestOptions)
        .then(handleResponse, handleError)
        .then(video => {


        });
}
function uploadBirthdayVideo(video) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderjSON(),
        body: JSON.stringify(video), 
     
    };

    return fetch(config.apiUrl + '/upload/UploadBirthdayVideo', requestOptions)
        .then(handleResponse, handleError)
        .then(video => {
        });
}


function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}