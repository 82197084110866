import React from 'react';
import { Link } from 'react-router-dom';
import ReactPiwik from 'react-piwik';
import { connect, } from 'react-redux';
import { downloadActions, alertActions } from '../../_actions';
import { QuickCms } from '../../_components/QuickCms';
import { getBlob } from '../../_helpers/base64.js'
import defaultThumbnail from '../../assets/default-thumbnail.png';
import gallery from '../../assets/thumbs/gallery.png';
import neu_Xarelto from '../../assets/thumbs/neu_Xarelto.png';
import A_Decade_of_Change from  '../../assets/thumbs/A_Decade_of_Change.png';
import Stories_of_Xarelto_Potpourri from  '../../assets/thumbs/Stories_of_Xarelto_Potpourri.png';
import PullUpBanners from '../../assets/thumbs/1-10_Pull_Up_Banners.png';
import A2_Poster from '../../assets/thumbs/A2_Poster.png';
import A5_PatientNumbersInfographic from '../../assets/thumbs/A5_Patient_Numbers_Infographic.png';
import A6_Flyer_EN_DE from '../../assets/thumbs/A6_Flyer_EN-DE.png';
import A6_Xarelto_Timeline_Leporello_Final_for_Print from '../../assets/thumbs/A6_Xarelto_Timeline_Leporello_Final_for_Print.png';
import A6_Xarelto_Timeline_Leporello_with_Fold_Lines from '../../assets/thumbs/A6_Xarelto_Timeline_Leporello_with_Fold_Lines.png';
import Awareness_Campaign_Banners from '../../assets/thumbs/Awareness_Campaign_Banners.png';
import Banner_500x234 from '../../assets/thumbs/Banner_500x234.png';
import Xarelto_Timeline from '../../assets/thumbs/Xarelto_Timeline.png';
import { config } from '../../_helpers';

class DownloadTownhallPage extends React.Component {

    componentDidMount() {
        this.bindDownloadLink();

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.file !== nextProps.file) {
            // this.downloadFile( nextProps.file);
         this.downloadFileByName( nextProps.file);
        }
    }
    bindDownloadLink() {
        var $this = this;
        $("[data-file]").click(function (e) {
            e.preventDefault();
            var fileName = $(this).attr("data-file");
           // $this.getFile(fileName);
         $this.getFileName(fileName);
        });

    }
    downloadFile(file) {
        var filename = file.filename
        var blob = file


        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);

        }
    


    }
  
    getFileName(fileName){
   
        const { dispatch } = this.props;
        const { file } = this.props;
        //dispatch(alertActions.request('Downloading..'));
        dispatch(downloadActions.getEncryptedFileName(fileName));
}

    getFile(fileName) {

        const { dispatch } = this.props;
        const { file } = this.props;
        dispatch(alertActions.request('Downloading..'));
        dispatch(downloadActions.getFile(fileName));
    }
    downloadFileByName(file){
       
            var elem = window.document.createElement('a');
            elem.href = config.apiUrl +"/download/getSecuredFile/?name="+file.name
            elem.download = file.filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
            this.submitAnalytics( file.filename);
         
       
      
      
    }
    submitAnalytics(filename){
        const { user } = this.props;
        var myevent = filename+" downloaded by user id:" + user.id;
    
        ReactPiwik.push(['trackEvent', 'File Download', myevent]);
    }
    render() {

        const { user } = this.props;
        const { file } = this.props;
        return (
            <div className="row">
                <div className="container download-toolkit-wrap">

                    <div className="col-md-12">
                        <QuickCms name="DownloadTownhallToolkitText">
                            <h2>Townhall Toolkit</h2>
                            <p>10 years is a significant milestone in the life of a brand, which we are celebrating with a dedicated Townhall event on 24 September 2018. </p>
                            <p>Materials put together for this special event are available to download here. A video of the Townhall event will be available to download on this page in the week following the event.</p>
                            <p> To request any of the videos displayed on this microsite, please email <a href="mailto:jana.rosenow@bayer.com">jana.rosenow@bayer.com</a></p>
                        </QuickCms>
                        <div>
                        <h3>You can download the following:</h3>
                        <div className="row">
                        
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="neu_Xarelto Anniversary Event_Townhall Presentation_200918.pptx">
                                                <img src={neu_Xarelto} /><br />
                                                <p>
                                                'Xarelto' Anniversary Event Townhall Presentation <br />(PDF, 340MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="Townhall_Photo_Gallery.zip">
                                                <img src={gallery} /><br />
                                                <p>
                                                Townhall Photo Gallery <br />(PDF, 17.4MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="A_Decade_of_Change_and_Innovation.mp4">
                                                <img src={A_Decade_of_Change} /><br />
                                                <p>
                                                A Decade of Change and Innovation <br />(PDF, 157MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="Stories_of_Xarelto_Potpourri.mp4">
                                                <img src={Stories_of_Xarelto_Potpourri} /><br />
                                                <p>
                                                Stories of 'Xarelto' Potpourri <br />(PDF, 64MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="1-10 Pull-Up Banners.PDF">
                                                <img src={PullUpBanners} /><br />
                                                <p>
                                                1-10 Pullup Banners <br />(PDF, 13MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="A5_Patient Numbers Infographic.PDF">
                                                <img src={A5_PatientNumbersInfographic} /><br />
                                                <p>
                                                Patient Numbers Infographic Postcard  <br />(PDF, 5.3MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="A6_Flyer EN-DE.PDF">
                                                <img src={A6_Flyer_EN_DE} /><br />
                                                <p>
                                                Announcement Flyer EN/DE <br />(PDF, 4.6MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                  
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="A2_Announcement Poster.PDF">
                                                <img src={A2_Poster} /><br />
                                                <p>
                                                Announcement Poster <br />(PDF, 9.3MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Xarelto Timeline Banner_600x250cm.PDF">
                                                <img src={Xarelto_Timeline} /><br />
                                                <p>
                                                Timeline Infographic Banner <br />(PDF, 812KB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="A6_Xarelto Timeline Leporello_with Fold Lines.PDF">
                                                <img src={A6_Xarelto_Timeline_Leporello_with_Fold_Lines} /><br />
                                                <p>
                                                Timeline Leporello (With Folds) <br />(PDF, 882KB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="A6_Xarelto Timeline Leporello_Final for Print.PDF">
                                                <img src={A6_Xarelto_Timeline_Leporello_Final_for_Print} /><br />
                                                <p>
                                                Timeline Leporello (Without Folds) <br />(PDF, 882KB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                            <a href="#" data-file="Foyer Banner_500x234cm.PDF">
                                                <img src={Banner_500x234} /><br />
                                                <p>
                                                    Foyer Banner <br />(PDF, 9.7MB)
                                                </p>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 col-12">
                                        <div className="Toolkit-download">
                                        <a href="#" data-file="Awareness Campaign Banners.PDF">
                                                <img src={Awareness_Campaign_Banners} /><br />
                                                <p>
                                                Awareness Campaign Banners (x3) <br />(PDF, 9.6MB)
                                   </p>
                                            </a>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                     


                       
                    </div>
                </div>
            </div>
        )
    }


}

function mapStateToProps(state) {
    const { authentication, download } = state;
    const { user } = authentication;
    const { file } = download;
    return {
        user, file
    };
}

const connectedDownloadTownhallPage= connect(mapStateToProps)(DownloadTownhallPage);
export { connectedDownloadTownhallPage as DownloadTownhallPage };