import React from 'react';
import { connect } from "react-redux"
import { config } from '../../_helpers';
import { uploadsActions } from '../../_actions';
import moment from 'moment';
import 'moment/locale/en-gb';
import { ViewStory } from '../ViewStory/ViewStory';

class AllStories extends React.Component {

    constructor(props) {
        super(props);

        this.viewDetails = this.viewDetails.bind(this);
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(uploadsActions.getAllUploads());
        moment.locale('en-gb');
    }
    
    viewDetails(upload) {
        this.props.uploads.uploadModel = upload;
        this.setState({
            uploads:this.props.uploads
        });
    }
    
    
    render() {
        const { uploads, file } = this.props;
        const { uploadModel} = uploads;
       
        return (
            <div className="row">
                <div className="col-sm-12 admin-uploads">



                    {uploads.loading && <h2>Loading uploads...</h2>}
                  
                    {!uploadModel && uploads.items &&
                        <div>
                            <h2>All Stories</h2>
                            <p>Review uploaded stories</p>

                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <td>Story Id</td>
                                   
                                        <td>Date Uploaded</td>
                                        <td>Story Date</td>
                                        <td>User Name</td>
                                        <td>Title</td>
                                        <td>Description</td>
                                        <td>Approved</td>
                                        <td></td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {uploads.items.map((upload, index) =>
                                        <tr key={upload.id} className={upload.isEdited?'edited':''}>
                                            <td>{upload.id}</td>


                                            <td>{moment(upload.dateCreated).format("DD MMM YYYY")}</td>
                                            <td>{moment(upload.uploadDate).format("DD MMM YYYY")}</td>
                                            <td>{upload.uploadUser.displayName}</td>
                                            <td>{upload.title}</td>
                                            <td>{upload.description.substring(0, 50)} ... </td>

                                            {upload.isApproved ?
                                                <td>Is Approved</td>
                                                : <td>Not Approved</td>
                                            }

                                            <td><a className="btn btn-primary" onClick={this.viewDetails.bind(this, upload)}>View</a></td>


                                        </tr>

                                    )}
                                </tbody>
                            </table>
                        </div>

                    }
                    {uploadModel &&
                        <ViewStory uploadModel={uploadModel} />
                    }

                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    const { uploads, authentication, download } = state;
    const { user } = authentication;
    const { file } = download;
    const {uploadModel} = uploads;

    return {
        user,
        uploads,
        file,
        uploadModel
    };
}

const connectedAllStories = connect(mapStateToProps)(AllStories);
export { connectedAllStories as AllStories };