import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { QuickCms } from '../../_components/QuickCms';
import { history } from '../../_helpers';

class NotFoundPage extends React.Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }
    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }
    render() {

        const { user } = this.props;
        return (
            <div className="row">
                <div className="container help-page">
                    <div className="col-sm-12">
                        <br />
                        <Link to="#" onClick={this.goBack}>❮ back to home </Link>
                        <QuickCms name="404text">
                            <h2>Page Not Found</h2>
                            <h2>Sorry, the page you requested cannot be found.</h2>
                            <p>
                                If you typed the page address in your browser's address bar, make sure that it is spelled correctly.
                                This page may have been removed or had its name changed, or it may be temporarily unavailable.
                             </p>
                             
                        </QuickCms>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}
const connectedNotFoundPage = connect(mapStateToProps)(NotFoundPage);
export { connectedNotFoundPage as NotFoundPage };