import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {config } from '../../_helpers';

export const AdminRoute = ({ component: Component,user, ...rest }) => (

    <Route {...rest} render={props => (

        user != null && user.isAdmin
            ? <Component {...props} />
            : <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        
    )} />
)