import React from 'react';
import { connect } from "react-redux"
import { config } from '../../_helpers';
import { uploadsActions,alertActions } from '../../_actions';
import moment from 'moment';
import { Editor } from '../Editor';

class ViewStory extends React.Component {

    constructor(props) {
        super(props);

        const { uploadModel, dispatch } = this.props;
        this.closeDetails = this.closeDetails.bind(this);
        this.deleteStory = this.deleteStory.bind(this);
        this.approveStory = this.approveStory.bind(this);
        this.getFileBlob = this.getFileBlob.bind(this);
        this.saveModel = this.saveModel.bind(this);
       
        this.state = {
            profileImageUrl :'',
            imageUrl :'',
            videoUrl:'',
        }

    }
    
    componentDidMount(){
        const { uploadModel } = this.props;
      

        if (uploadModel.uploadUser.profileImage) {
            this.getFileBlob(uploadModel.uploadUser.profileImage, uploadModel.id, "profileImage");
        }
        if (uploadModel.image) {
            this.getFileBlob(uploadModel.image, uploadModel.id,"image");
        }
        if (uploadModel.video) {
            this.getFileBlob(uploadModel.video, uploadModel.id,"video");
        }
    }
    
    componentWillReceiveProps(nextprop) {
     
       
        const { file, uploadModel } = this.props; 
        var files = [];

        if (file != nextprop.file) {
          
            this.displayFile(nextprop.file);
        }
           
       if(nextprop.uploadModel.uploadUser.profileImageBase64){
         
            this.setState({profileImageUrl:nextprop.uploadModel.uploadUser.profileImageBase64})
        }
        if(nextprop.uploadModel.imageBase64){
            this.setState({imageUrl:nextprop.uploadModel.imageBase64})
        }
        if( nextprop.uploadModel.videoBase64){
            this.setState({videoUrl:nextprop.uploadModel.videoBase64})
        }

    }
    
    getFileBlob(filename, uploadId, type) {
        const { dispatch } = this.props;
        dispatch(uploadsActions.getFile(filename,uploadId, type));
    }

    displayFile(file) {
       
        if(!file){return};
        var filename = file.filename
      
            switch(file.filetype) {
                case 'video':
                this.setState({videoUrl: window.URL.createObjectURL(file)});
                    break;
                 case 'image': 
                this.setState({imageUrl: window.URL.createObjectURL(file)});
                break;
                 case 'profileImage': 
                 this.setState({profileImageUrl: window.URL.createObjectURL(file)});
                break;
        }

    }
   

    closeDetails(e) {
        e.preventDefault();
        this.props.dispatch(uploadsActions.closeDetails());
       

    }
    saveModel(e){
        e.preventDefault();
        const {uploads,dispatch} = this.props;
        const{uploadModel} = uploads;
       var story = this.mapModelToStory(uploadModel);
       dispatch(alertActions.request('Processing..'));
       dispatch(uploadsActions.editStory(uploadModel,story));
    }
    mapModelToStory(uploadModel){
      
        return {
            uploadId:  uploadModel.id,
            userId:uploadModel.uploadUser.id,
            name: uploadModel.uploadUser.displayName,
            jobTitle: uploadModel.uploadUser.title,
            region: uploadModel.uploadUser.region,
            country: uploadModel.uploadUser.country,
            gender:  uploadModel.uploadUser.gender,
            memorableDate: moment(uploadModel.uploadDate).format("DD/MM/YYYY"),
            profilePhoto: uploadModel.uploadUser.profileImage,
            profilePhotoBase64: uploadModel.uploadUser.profileImageBase64,
            memorablePhoto: uploadModel.image,
            memorablePhotoBase64:uploadModel.imageBase64,
            video: uploadModel.video,
            videoBase64:  uploadModel.videoBase64,
            storyTitle:  uploadModel.title,
            description: uploadModel.description,
            agreeTerms: true,
            agreeTransparent: true,
        }
    }

    deleteStory(e) {
        e.preventDefault();
        var id = $(e.target).attr("data-id");
       this.props.dispatch(uploadsActions.deleteStory(id));
    }
    approveStory(e) {
         e.preventDefault();
         var id = $(e.target).attr("data-id");
         this.props.dispatch(uploadsActions.approveStory(id));
    }
    render() {
        const {file, uploadModel} = this.props;
        const {imageUrl, videoUrl, profileImageUrl} = this.state;

        return (
          <div className="profile-detail-pop-up">
                              
                            <div className="container">
                            <div className="row admin-uploads-top-bar">
                               <div className="col-sm-6 col-xs-6">
                               <a href="#" onClick={this.closeDetails} className="btn btn-primary purple-btn close-btn">❮ back </a>
                             
                               </div>
                               <div className="col-sm-6 col-xs-6 align-right">
                               {!uploadModel.isEditing && uploadModel.isDirty &&
                                          <a href="#" onClick={this.saveModel} className="btn btn-primary"> <i className="fa fa-floppy-o" aria-hidden="true"></i> SAVE</a>
                                    }
                                     {!uploadModel.isDirty &&
                                     <div>
                                    <a href="#" data-id={uploadModel.id} onClick={this.approveStory} className="btn btn-primary">{uploadModel.isApproved?'Deny':'Approve'}</a>
                                    <a href="#" data-id={uploadModel.id}  onClick={this.deleteStory}className="btn btn-primary purple-btn"><i className="fa fa-trash" aria-hidden="true"></i>Delete</a>
                                     </div>
                                     }
                               </div>
                            </div>
              
                                <div className="row">
                                    <div className="col-md-10">
                                        <h2>Upload Id {uploadModel.id} {uploadModel.uploadUser.displayName}</h2>
                                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                                            <div>
                                                {uploadModel.uploadUser.profileImage&&
                                                    <img src={profileImageUrl} id="profileImage" width="100%" />
                                                }
                                                {!uploadModel.uploadUser.profileImage &&
                                                    <label htmlFor={uploadModel.gender}></label>
                                                }
                                            </div>

                                        </div>
                                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                                            <div className="row">
                                                <div className="col-sm-12 padding-0">
                                                    <p>
                                                        <strong>User Id:</strong> {uploadModel.uploadUser.id} 
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2 padding-0">
                                               
                                                    <p>
                                                    <strong>Display Name:</strong>
                                                    </p>
                                                </div>
                                                <div className="col-sm-9 padding-0">
                                                <Editor type="text" name="uploadUser.displayName" value={uploadModel.uploadUser.displayName} />

                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-sm-2 padding-0">
                                                    <p>
                                                    <strong>Function:</strong> 
                                                    </p>
                                                </div>
                                                <div className="col-sm-9 padding-0">
                                                <Editor type="text" name="uploadUser.title" value={uploadModel.uploadUser.title} />

                                                </div>
                                            </div>
                                          

                                            <div className="row">
                                                <div className="col-sm-2 padding-0">
                                                    <p>
                                                    <strong>Region:</strong>
                                                    </p>
                                                 
                                                </div>
                                                <div className="col-sm-9 padding-0">
                                                <Editor type="select" name="uploadUser.region" value={uploadModel.uploadUser.region} />

                                                </div>
                                            </div>

                                             
                                            <div className="row">
                                                <div className="col-sm-2 padding-0">
                                                    <p>
                                                     <strong>Country:</strong> 
                                                    </p>
                                           
                                                </div>
                                                <div className="col-sm-9 padding-0">
                                                <Editor type="select" name="uploadUser.country" value={uploadModel.uploadUser.country} />

                                                </div>
                                            </div>

                                        <div className="row">
                                            <div className="col-sm-2 padding-0">

                                                <p> <strong>Email:</strong>  </p>
                                            </div>
                                                <div className="col-sm-10 padding-0">
                                                <p>
                                                {uploadModel.uploadUser.email}
                                                </p>
                                                </div>
                                        
                                        </div>
                                             


                                            


                
                                            {uploadModel.uploadUser.profileImage == '' &&
                                               <div className="left"> <strong>Gender:</strong> <Editor type="text" name="uploadUser.gender" value={uploadModel.uploadUser.gender} /></div>
                                                
                                            }
                                          
                                               <div className="row">
                                                   <div className="col-sm-2 padding-0">
                                                        <p>
                                                        <strong>Profile image:</strong>
                                                        </p>
                                                   </div>
                                                  <div className="col-sm-10 padding-0">
                                                  <Editor type="file" name="uploadUser.profileImage" value={uploadModel.uploadUser.profileImage} />

                                                  </div>

                                                </div> 
                                                
                                            
                                        </div>
                                    </div>
                                    <br />
                                
                                </div>
                                <hr />

                                    <div className="row">
                                        <div className="col-sm-12 padding-0">
                                            <h2>Story</h2>
                                        </div>
                                    </div>
                                <div className="row story-details">
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <div className="col-sm-3 padding-0">
                                                <p>
                                                <strong>Approved:</strong> 
                                                 </p>
                                            </div>
                                            <div className="col-sm-9 padding-0">
                                                 {uploadModel.isApproved ? <span>Is Approved</span> : <span>Not Approved </span>}
                                            </div>
                                        </div>
                                       <div className="row">
                                           <div className="col-sm-3 padding-0">
                                           <p>
                                             <strong>Date uploaded:</strong> 
                                            </p>
                                           </div>
                                           <div className="col-sm-9 padding-0">
                                                {moment(uploadModel.dateCreated).format('DD MMM YYYY')} 
                                           </div>
                                       </div>

                                       <div className="row">
                                           <div className="col-sm-3 padding-0">
                                         <p>  <strong>Memorable Date:</strong></p>
                                           </div>
                                           <div className="col-sm-9 padding-0 upload-date">
                                           <Editor type="cal" name="uploadDate" value={uploadModel.uploadDate} />
                                           </div>
                                       </div>
                                       
                                       <div className="row">
                                           <div className="col-sm-3 padding-0">
                                          <p> <strong>Title:</strong></p>
                                           </div>
                                           <div className="col-sm-9 padding-0">
                                           <Editor type="text" name="title" value={uploadModel.title} />
                                           </div>
                                       </div>
                                           
                                       <div className="row">
                                           <div className="col-sm-3 padding-0">
                                          <p>    <strong>Memorable photo file:</strong></p>
                                           </div>
                                           <div className="col-sm-9 padding-0">
                                           <Editor type="file" name="image" value={uploadModel.image} />
                                           </div>
                                       </div> 

                                       <div className="row">
                                           <div className="col-sm-3 padding-0">
                                          <p>   <strong>Video fie:</strong></p>
                                           </div>
                                           <div className="col-sm-9 padding-0">
                                           <Editor type="file" name="video" value={uploadModel.video} />
                                           </div>
                                       </div> 
                                       
                                        

                                    </div>
                                   

                                </div>
                                <div className="row">
                                    <div className="col-md-12 padding-0">
                                        <div>
                                           <p> <strong>Description</strong> <br/></p>
                                            <Editor type="textarea" name="description" value={uploadModel.description} />
                                     </div>
                                    </div>
                                </div>
                  
                                  <div className="row">
                                        <div className="col-sm-12 padding-0">
                                        {(uploadModel.image || uploadModel.video) &&
                                        <h2>Memorable Photo and video</h2>
                                        }
                                        </div>
                                    </div>
                                <div className="row">
                                    {uploadModel.image &&
                                        <div className="col-sm-12 col-md-4">
                                            <img src={imageUrl} id="image" width="300" />
                                        </div>
                                    }
                                    {uploadModel.video &&
                                        <div className="col-sm-12 col-md-8">

                                            <video src={videoUrl } id="video" width="720 " height="480" controls>
                                           
                                            </video>
                                        </div>
                                    }
                                </div>
                             


                            </div>

                        </div>
        )
    }
}

function mapStateToProps(state) {
    const { uploads, authentication, download } = state;
    const { user } = authentication;
    const { file } = download;

    return {
        user,
        uploads,
        file
    };
}

const connectedViewStory = connect(mapStateToProps)(ViewStory);
export { connectedViewStory as ViewStory };