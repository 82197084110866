import React from 'react';
import ReactPiwik from 'react-piwik';
import { Route } from 'react-router-dom';
import { history } from '../../_helpers';
import { connect } from 'react-redux';

class Piwik extends React.Component {

  constructor(props){
    super(props);
  
    const piwik = new ReactPiwik({
        url: 'https://stats.x-lr.com',
        siteId: 65,
        trackErrors: true,
    });
    piwik.connectToHistory(history);
  }

  
  componentDidMount(){
  
  }
 
 render(){
  
    return (
   
      <div ></div>
       
    )
 
};
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
      user  
    };
}

const connectedPiwik = connect(mapStateToProps)(Piwik);
export { connectedPiwik as Piwik };
