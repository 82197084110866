import { userConstants } from '../_constants';
import { downloadService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const downloadActions = {
    getFile,
    getEncryptedFileName
};

function getFile(filename) {
    return dispatch => {
        downloadService.getFile(filename)
        .then(
            (file) => {
                file.filename = filename;
                dispatch(success(file));
                dispatch(alertActions.clear());
               


            },
            error => {
                console.log(error);
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

        function success(file) { return { type: userConstants.DOWNLOAD_SUCCESS, file } }
        function failure(error) { return { type: userConstants.DOWNLOAD_FAILURE, error } }

    }
}
    function getEncryptedFileName(filename) {
        return dispatch => {
            downloadService.getEncryptedFileName(filename)
            .then(
                (file) => {
                    file.filename = filename;
                    dispatch(success(file));
                    dispatch(alertActions.clear());
                   
    
    
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    
            function success(file) { return { type: userConstants.DOWNLOAD_SUCCESS, file } }
            function failure(error) { return { type: userConstants.DOWNLOAD_FAILURE, error } }
    
        }

}