
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from 'react-calendar';
import { videoActions, alertActions } from '../../_actions';
import { countryData, regionData } from  '../../_helpers/data.js'
import { getBase64 } from '../../_helpers/base64.js'
import { QuickCms } from '../../_components/QuickCms';

import { noValidateList, checkEntryisValid, validateFileType, checkOptionalsAreValid } from '../../_helpers/validation.js'

class VideoUpload extends React.Component {
    constructor(props) {
        super(props);
        const { user } = this.props;

        this.state = {
            model: {
                name: user.displayName,
                jobTitle: '',
                region: user.region,
                country: user.country,
                gender: '',
                memorableDate: '',
                profilePhoto: '',
                profilePhotoBase64: '',
                memorablePhoto: '',
                memorablePhotoBase64: '',
                video: '',
                videoBase64: '',
                storyTitle: '',
                description: '',
                agreeTerms: '',
                agreeTransparent: '',

            },

            submitted: false,
            invalidFiles: []
        };
        this.clearItem = this.clearItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {

        this.bindDropdowns();
    }
    bindDropdowns() {
        const { model } = this.state;
        $.each(countryData, function (i, option) {
            $('#country').append($('<option/>').attr("value", option.id).text(option.name));

        });
        $.each(regionData, function (i, option) {
            $('#region').append($('<option/>').attr("value", option.name).text(option.name));
        });
        $('#country').val(model.country);
        $('#region').val(model.region);
    }
    handleDateChange(date) {
        var momentObj = moment(date);
        var datestr = momentObj.format('DD/MM/YYYY');
        //  var datestr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        var name = "memorableDate";
        const { model } = this.state;
        this.setState({
            model: {
                ...model,
                [name]: datestr
            }
        });

        $("#memorableDate").val(datestr);
        $(".calendar").toggle();


    }
    showCalendar() {
        $(".calendar").toggle();

    }
    clearItem(event) {
        const { model } = this.state;
        event.preventDefault();
        var name = $(event.target).attr("data-target");

        this.updateModel(name, '');


    }


    handleChange(event) {
        const { name, value } = event.target;
        this.updateModel(name, value);
    }
    updateModel(name, value) {
        const { model } = this.state;
        this.setState(
            {
                model: {
                    ...model,
                    [name]: value
                }
            }, () => this.itemStateChanged(name)
        );


    }
    itemStateChanged(name) {
        const { model, invalidFiles, fileIsInvalid } = this.state;
        if (name === "profilePhoto" || name === "memorablePhoto" || name === "video") {

            this.setBase64ToModel(model, name);

        }
        if (!checkEntryisValid(name, model)) {
            console.log(name + "is invalid")
            if (invalidFiles.indexOf(name) == -1) {
                invalidFiles.push(name);
                this.setState({ invalidFiles: invalidFiles });
            }
        }
        else {
            var index = invalidFiles.indexOf(name);
            if (index > -1) {
                invalidFiles.splice(index, 1);
            }
        }

    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const { model } = this.state;
        const { dispatch } = this.props;

        //validate model
        if (this.doValidation(model, noValidateList)) {

            console.log("its all valid... submitting!");
            dispatch(alertActions.request('Processing..'));
            dispatch(videoActions.uploadVideo(model));
        }

    }
    doValidation(model, noValidateList) {

        var isValid = false;
        const { invalidFiles } = this.state;
        for (var item in model) {


            if (model[item] == '' && noValidateList.indexOf(item) == -1) {
                console.log("something that is required is empty")
                return false;
            }
            else if (!checkEntryisValid(item, model)) {
                console.log(item + " is invalid")
                if (invalidFiles.indexOf(item) == -1) invalidFiles.push(item);

                this.setState({ invalidFiles: invalidFiles });
                return false;
            }
        }

        return checkOptionalsAreValid(model);

    }

    setBase64ToModel(model, name) {

        var file = document.querySelector('#' + name).files[0];
        if (!file) {
            this.setState(prevState => ({
                model: {
                    ...prevState.model,
                    [name + "Base64"]: ''
                }
            }));

            return;
        }
        getBase64(file).then(
            data => this.setState(prevState => ({
                model: {
                    ...prevState.model,
                    [name + "Base64"]: data
                }
            }))).then(() => {

            });


    }

    render() {
        const { sending } = this.props;
        const { model, submitted, invalidFiles, fileIsInvalid } = this.state;
        return (
            <div>
                <div className="row">

                    <div className="col-md-12">
                        <QuickCms name="SubmitStoryText">
                            <h2>Add Your Story</h2>
                            <p>Loading...</p>
                        </QuickCms>
                    </div>
                    <div className="col-md-12 video-guide">
                        <QuickCms name="VideoGuideance">
                            <h2>Video Guidance</h2><p>If you plan to submit a video, please take a look at recommendations below. We want to ensure that we receive high quality videos that can be shared not only on this microsite, but also with other supporting materials to celebrate the 10-Year Anniversary.</p>

                            <ul>
                                <li><strong>CONTENT: </strong>We want to hear local stories of success and celebration that can be shared with colleagues around the world. Please keep the content in English. Whatever your story, please begin or end by saying “Happy Birthday ‘Xarelto’”. This is to allow us to create a montage video of colleagues from around the world wishing ‘Xarelto’ a happy birthday!</li>
                                <li><strong>LENGTH: </strong> Videos should be no longer than one minute, so think carefully about what content you want to include. We would suggest practising a couple of times before filming your final entry to make sure you are able to get your story told within the one minute time frame.</li>
                                <li><strong>QUALITY: </strong> Where possible, videos should be filmed landscape (not portrait) and should be filmed on an iPhone or equivalent. A full filming kit is not necessary!</li>
                                <li><strong>SOUND: </strong>Make sure sound is enabled on your camera, and that there is no distracting background noise.</li>
                                <li><strong>LIGHTING: </strong> Ensure your video is well lit. Too dark and the subject will be hard to distinguish, too light and the quality of the image will be distorted.</li>
                                <li><strong>DISTANCE: </strong>Please ensure that you and your team are a reasonable distance from the camera. If you are too far away we won’t be able to see you all clearly, too close and some of you may be cut out of the frame.</li>
                            </ul>
                        </QuickCms>

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-12">
                        <div className="video-upload-container">

                            <p>Boxes marked <sup>*</sup> are mandatory</p>

                            <div className="upload-form">

                                <form name="form" onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h2>Your Details</h2>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.name ? ' has-error' : '')}>
                                                <label htmlFor="name">Your Name <sup>*</sup></label>
                                                <input type="text" className="form-control" name="name" placeholder="Name" value={model.name} onChange={this.handleChange} />
                                                {submitted && !model.name &&
                                                    <div className="help-block">Your name is required</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.jobTitle ? ' has-error' : '')}>
                                                <label htmlFor="jobTitle">Your Function <sup>*</sup></label>
                                                <input type="text" className="form-control" name="jobTitle" placeholder="Your Function" value={model.jobTitle} onChange={this.handleChange} />
                                                {submitted && !model.jobTitle &&
                                                    <div className="help-block">Your function is required</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.region ? ' has-error' : '')}>
                                                <label className="cutom-drop-down-arrow" htmlFor="region">Your Region <sup>*</sup>
                                                    <select className="form-control" id="region" name="region" placeholder="Select Region" value={model.region} onChange={this.handleChange} ><option value="">Select Region</option></select>
                                                    {submitted && !model.region &&
                                                        <div className="help-block">Your region is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.country ? ' has-error' : '')}>
                                                <label className="cutom-drop-down-arrow" htmlFor="country">Your Country <sup>*</sup>
                                                    <select className="form-control" id="country" name="country" placeholder="Select Country" value={model.country} onChange={this.handleChange} ><option value="">Select Country</option></select>
                                                    {submitted && !model.country &&
                                                        <div className="help-block">Your country is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className={'form-group' + (submitted && ((!model.profilePhoto && !model.gender)) || invalidFiles.indexOf("profilePhoto") != -1 ? ' has-error' : '')}>

                                                        <label htmlFor="profilePhoto">Upload a Photo of Yourself, or Select Avatar <sup>*</sup></label>
                                                        <div className="upload-input">
                                                            <input type="text" className="form-control upload-file" readOnly name="profilePhoto" placeholder="Choose file" value={model.profilePhoto} onChange={this.handleChange} />
                                                            <div className="custom_upload_button">
                                                                <button className="btn">Browse</button>
                                                                <input type="file" id="profilePhoto" name="profilePhoto" value={model.profilePhoto} onChange={this.handleChange} />
                                                            </div>
                                                            {model.profilePhoto != '' &&
                                                                <div className="clear-field"><a href="#" data-item="profilePhoto" onClick={this.clearItem}> <i data-target="profilePhoto" className="fa fa-times-circle"></i></a></div>
                                                            }
                                                        </div>

                                                        {submitted && (!model.profilePhoto && !model.gender) &&
                                                            <div className="help-block">Your photo or avatar is required</div>
                                                        }
                                                        {invalidFiles.indexOf("profilePhoto") != -1 &&
                                                            <div className="help-block">Your photo must be a .jpg or.png</div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-xs-12">
                                                    <div className={'form-group'}>
                                                        <div className="radio-buttons">

                                                            <input type="radio" className="form-control left" id="male" name="gender" value="male" onChange={this.handleChange} />
                                                            <label htmlFor="male"></label>

                                                            <input type="radio" className="form-control left" id="female" name="gender" value="female" onChange={this.handleChange} />
                                                            <label htmlFor="female"></label>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <QuickCms name="YourStoryText">
                                                <h2>Your Story</h2>
                                                <p>
                                                    Your story could be text, an image, and/or a short video. Please keep the content in English so that everybody can understand it and gets motivated. You can view more guidance on your story <a href="/help"> here</a>.
                                    </p>

                                            </QuickCms>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.memorableDate ? ' has-error' : '')}>
                                                <label className="custom-date-icon" htmlFor="memorableDate">Memorable Date <sup>*</sup>
                                                    <span className="glyphicon glyphicon-calendar"></span>
                                                    <input type="text" className="form-control calendar-input" readOnly name="memorableDate" placeholder="Select a memorable date" onClick={this.showCalendar} value={model.memorableDate} onChange={this.handleChange} />
                                                    <Calendar className="calendar" minDetail="year" view="month" onChange={this.handleDateChange} />

                                                    {submitted && !model.memorableDate &&
                                                        <div className="help-block">Your memorable date is required</div>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + (submitted && !model.storyTitle ? ' has-error' : '')}>
                                                <label htmlFor="storyTitle">Your Story Title <sup>*</sup></label>
                                                <input type="text" className="form-control" name="storyTitle" placeholder="Enter a title" value={model.storyTitle} onChange={this.handleChange} />
                                                {submitted && !model.storyTitle &&
                                                    <div className="help-block">Your story title is required</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + ((submitted && !model.memorablePhoto) || (invalidFiles.indexOf("memorablePhoto") != -1) ? ' has-error' : '')}>

                                                <label htmlFor="memorablePhoto">Your Memorable Photo </label>
                                                <div className="upload-input">
                                                    <input type="text" className="form-control upload-file" name="memorablePhoto" readOnly placeholder="Choose file" value={model.memorablePhoto} onChange={this.handleChange} />

                                                    <div className="custom_upload_button">
                                                        <button className="btn">Browse</button>
                                                        <input type="file" id="memorablePhoto" name="memorablePhoto" value={model.memorablePhoto} onChange={this.handleChange} />
                                                    </div>

                                                    {model.memorablePhoto != '' &&
                                                        <div className="clear-field"><a href="#" onClick={this.clearItem}> <i data-target="memorablePhoto" className="fa fa-times-circle"></i></a></div>
                                                    }
                                                </div>
                                                <strong>Maximum file size: 5MB</strong>
                                                {(invalidFiles.indexOf("memorablePhoto") != -1) &&
                                                    <div className="help-block">Your memorable photo must be a .jpg or.png</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className={'form-group' + ((submitted && !model.video) || (invalidFiles.indexOf("video") != -1) ? ' has-error' : '')}>
                                                <label htmlFor="video">Your Memorable Video</label>
                                                <div className="upload-input">
                                                    <input type="text" className="form-control upload-file" readOnly name="video" placeholder="Choose file" value={model.video} onChange={this.handleChange} />
                                                    <div className="custom_upload_button">
                                                        <button className="btn">Browse</button>
                                                        <input type="file" id="video" name="video" value={model.video} onChange={this.handleChange} />
                                                    </div>
                                                    {model.video != '' &&
                                                        <div className="clear-field"><a href="#" onClick={this.clearItem}> <i data-target="video" className="fa fa-times-circle"></i></a></div>
                                                    }
                                                </div>
                                                <strong>Maximum length: 1 minute. File format MP4 or MOV only</strong>
                                                {(invalidFiles.indexOf("video") != -1) &&
                                                    <div className="help-block">Your video must be a .mov or .mp4</div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">

                                            <div className={'form-group' + ((submitted && !model.description) || (model.description.split(" ").length >= 400) ? ' has-error' : '')}>
                                                <label htmlFor="description">Your Story Description</label>
                                                <textarea className="form-control message-field" name="description" placeholder="Enter a short description about your video" value={model.description} onChange={this.handleChange} />
                                                <strong>Maximum word count: 400 words</strong>
                                                {submitted && (!model.memorablePhoto && !model.video && !model.description) &&
                                                    <div className="help-block">You must choose a memorable photo, video or write a story description</div>
                                                }

                                                {model.description.split(" ").length >= 400 &&

                                                    <div className="help-block">Your story description is more than 400 words.</div>
                                                }
                                            </div>


                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 custom-check-box">
                                            <div className={'form-group ' + (submitted && !model.agreeTerms ? ' has-error' : '')}>

                                                <input type="checkbox" name="agreeTerms" id="agreeTerms" value="true" onChange={this.handleChange} />
                                                <label htmlFor="agreeTerms">I confirm that I have read and agree to the <a href="/conditionsofuse" target="_blank">terms and conditions</a>.</label>
                                                {submitted && !model.agreeTerms &&
                                                    <div className="help-block">You must accept the terms and conditions</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-12 custom-check-box">
                                            <div className={'form-group' + (submitted && !model.agreeTransparent ? ' has-error' : '')}>

                                                <input type="checkbox" name="agreeTransparent" id="agreeTransparent" value="true" onChange={this.handleChange} />
                                                <label htmlFor="agreeTransparent">Data submitted in this form will be processed by an external agency for addition to the timeline on the 'Xarelto' One2Ten website. Information will be stored for the lifetime of the website, unless you request it to be removed. Stories may also be shared on various internal multimedia channels. Please tick to confirm that you understand and accept that your information will be used in this way.</label>
                                                {submitted && !model.agreeTransparent &&
                                                    <div className="help-block">You must confirm that you understand how the information provided will be used</div>
                                                }
                                            </div>


                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group submit-buttons">
                                                <button className="btn btn-primary">Submit</button>
                                                {sending &&
                                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                                <a href="/help" className="btn btn-link" target="_blank"><span>?</span><u>Need help?</u></a>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedVideoUpload = connect(mapStateToProps)(VideoUpload);
export { connectedVideoUpload as VideoUpload };
